import { Context } from '@/context';
import { VERSIONS_DATA, getVersionImageColor } from '@/data/versions.data';
import DataLayer from '@/utils/DataLayer';
import { useContext, useEffect } from 'react';
import styles from './color-change.module.scss';

const ColorChanger: React.FC = () => {
  const { VERSION_CONTEXT } = useContext(Context);


  const handleColorClick = (
    colorNameFormatted: string,
    colorCode: string
  ): void => {
    VERSION_CONTEXT.set_selected_color_name(colorNameFormatted);
    VERSION_CONTEXT.set_selected_color(colorCode);
    VERSION_CONTEXT.set_version_src(
      getVersionImageColor(
        VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index].mvsCode,
        colorCode
      )
    );
  };

  return (
    <div className={styles.colorsContainer}>
      <p>Selecione a cor do seu Fastback</p>
      <div className={styles.colors}>
        <nav>
          {VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index].colors.map(
            (color, i) => {
              if (i === 0 && !VERSION_CONTEXT.get_selected_color_name) {
                handleColorClick(color.colorNameFormatted, color.colorCode);
              }

              return (
                <button
                  className={
                    VERSION_CONTEXT.get_selected_color_name ===
                    color.colorNameFormatted
                      ? styles.active
                      : ' '
                  }
                  key={`color-bullet-${i}`}
                  onClick={() => {
                    DataLayer.clickEvent({
                      element: color.colorName,
                      elementCategory: 'icone',
                      pageSection: 'versao',
                      pageSubsection: `fastback-${
                        VERSIONS_DATA[
                          VERSION_CONTEXT.get_selected_version_index
                        ].slug
                      }`,
                    });

                    handleColorClick(color.colorNameFormatted, color.colorCode);
                  }}
                >
                  <span
                    className={styles.currCircleColor}
                    style={{
                      background: `radial-gradient(circle at 10px 14px, #${color.colorRGB} 9px, #${color.colorRGB} 10px)`,
                    }}
                    title={color.colorName}
                  />
                  <span className={styles.currTextColor}>
                    <div
                      style={{ backgroundColor: `#fff` }}
                      className={styles.teste}
                    ></div>
                    <div className={styles.color_active_name}>
                      {color.colorName}
                    </div>
                  </span>
                </button>
              );
            }
          )}
        </nav>
      </div>
    </div>
  );
};

export default ColorChanger;
