import { SectionElement, Stories } from "@components/index";
import { FC } from "react";
import styles from "./stories-section.module.scss";
import { observer } from "mobx-react-lite";
import { dataMenuLabels } from "@/data/menu.data";
import { dataHero, IHero } from "@/data/hero.data";
import { PREFIX } from "@/utils/imagePrefixes";
import NewFastback from "@/components/SvgComponents/NewFastback";

const MainStories: FC = observer(() => {
  const items: IHero[] | any = [];

  dataHero.map((item, index) =>
    items.push({
      showNext: true,
      nextTitle: item.cardTitle,
      title: item.titleStories,
      description: item.descriptionStories,
      body: (
        <>
          <div className={styles.slides}>
            {item.id === "0" ? (
              <div className={styles.svgContent}>
                <NewFastback />
              </div>
            ) : (
              <div className={styles.slidesContainer}>
                <div className={styles.bottomBox}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200.173"
                    height="11.046"
                    viewBox="0 0 200.173 11.046"
                    style={{
                      marginBottom: "12px",
                    }}
                  >
                    <g
                      id="Grupo_4238"
                      data-name="Grupo 4238"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_4630"
                        data-name="Caminho 4630"
                        d="M261.584,0,258,11.046h1.659L263.244,0Z"
                        transform="translate(-111.803)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4631"
                        data-name="Caminho 4631"
                        d="M304.584,0,301,11.046h1.659L306.244,0Z"
                        transform="translate(-130.436)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4632"
                        data-name="Caminho 4632"
                        d="M347.584,0,344,11.046h1.659L349.243,0Z"
                        transform="translate(-149.07)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4633"
                        data-name="Caminho 4633"
                        d="M132.584,0,129,11.046h1.659L134.243,0Z"
                        transform="translate(-55.901)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4634"
                        data-name="Caminho 4634"
                        d="M3.584,0,0,11.046H1.659L5.243,0Z"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4635"
                        data-name="Caminho 4635"
                        d="M46.584,0,43,11.046H44.66L48.244,0Z"
                        transform="translate(-18.634)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4636"
                        data-name="Caminho 4636"
                        d="M89.584,0,86,11.046h1.659L91.244,0Z"
                        transform="translate(-37.268)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4637"
                        data-name="Caminho 4637"
                        d="M175.584,0,172,11.046h1.659L177.243,0Z"
                        transform="translate(-74.535)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4638"
                        data-name="Caminho 4638"
                        d="M218.584,0,215,11.046h1.659L220.244,0Z"
                        transform="translate(-93.169)"
                        fill="#ffb80f"
                      />
                    </g>
                  </svg>
                  {index > 0 && <h2>{item.titleStories}</h2>}
                  <p className={styles.contentText}>
                    {item.descriptionStories}
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ),
      scrollCtaOrientation: "vertical",
      durationInS: 15,
      teste: item.src.mobile,
      titleTag: item.titleTag,
      altTag: item.altTag,
    })
  );
  return (
    <SectionElement
      id="mobileStories"
      navReference={dataMenuLabels()[0]}
      className={styles.container}
    >
      <Stories items={items} />
    </SectionElement>
  );
});

export default MainStories;
