import { COLORS } from '@/styles/variables';

const PCDToolTip = () => (
  <div
    style={{
      backgroundColor: 'white',
      position: 'absolute',
      whiteSpace: 'nowrap',
      left: '-200px',
      color: COLORS.BASIC.BLACK,
      padding: 10,
    }}
  >
    <strong style={{ fontSize: 16 }}>
      Valores sujeitos a alteração para clientes PCD.
    </strong>
    <br />
    <small style={{ fontSize: 12 }}>
      Consulte os descontos
      <a
        style={{ marginLeft: 5 }}
        target="_blank"
        href="https://www.fiat.com.br/vendas-diretas/pessoas-com-deficiencia.html"
      >
        clicando aqui
      </a>
    </small>
  </div>
);

export default PCDToolTip;
