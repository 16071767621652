import { gallery, galleryThumbs } from "@/assets/gallery";
import {
  ArrowButton,
  Conditional,
  IconButton,
  SectionElement,
  TitleBox3d,
  VimeoEmbed,
} from "@/components";
import { Chevron } from "@/components/chevron";
import { Context } from "@/context";
import { dataMenuLabels } from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import getRangeOfEntries from "@/utils/getRangeOfEntries";
import scssStyles from "@/utils/scssStyles";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import { FC, useCallback, useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ExpandedGallery from "./expanded-gallery";
import styles from "./gallery-section.module.scss";
import { Magnifying } from "@/components/SvgComponents";

SwiperCore.use([A11y, Keyboard, Thumbs]);

const reference = dataMenuLabels().find((c) => c.slug === "galeria");

export const GalleryV1: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [controller, setController] = useState<SwiperCore>();
  const pageSubsection = "galeria";

  const findIndex = () => {
    return gallery.findIndex(
      (g) => g.id === state.layout.selectedGalleryImage?.id
    );
  };

  const updateIndex = useCallback(
    (n: number) => {
      dispatch({
        type: "SET_GALLERY_IMAGE",
        payload: { selectedGalleryImage: gallery[n] },
      });
      if (controller && typeof n === "number") {
        controller?.slideTo(n);
      }
    },
    [controller, dispatch]
  );

  const next = () => {
    const index = findIndex();
    const nIndex = index + 1 > gallery.length - 1 ? 0 : index + 1;
    updateIndex(nIndex);
  };
  const previous = () => {
    const index = findIndex();
    const nIndex = index - 1 < 0 ? gallery.length - 1 : index - 1;
    updateIndex(nIndex);
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      DataLayer.swipeEvent({
        element: "proximo",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });
      next();
    },
    onSwipedRight: () => {
      DataLayer.swipeEvent({
        element: "anterior",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });
      previous();
    },
  });

  const bulletProps = getRangeOfEntries(findIndex(), gallery, 6);

  return (
    <>
      <SectionElement
        id="Gallery"
        className={styles.container}
        navReference={reference}
      >
        <div className={styles.galleryContainer}>
          <div className={styles.title}>
            {state.layout.isDesktop && (
              <h2>
                {" "}
                CONHEÇA A <span>SUV COUPÉ</span> QUE VEM COM TUDO
              </h2>
            )}
            {!state.layout.isDesktop && (
              <h2 className={styles.alignerTerxCenter}>
                CONHEÇA A NOVA
                <br />
                <span>SUV COUPÉ</span>
              </h2>
            )}
          </div>

          <div className={styles.aligner}>
            <div className={styles.mainImage}>
              {!state.layout.selectedGalleryImage?.video && (
                <IconButton
                  className={styles.expandIcon}
                  title="Expandir imagem"
                  icon={
                    <>
                      <Magnifying />
                    </>
                  }
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: `amplia-foto-${
                        gallery.findIndex(
                          c => c.id === state.layout.selectedGalleryImage.id
                        ) + 1
                      }`,
                      elementCategory: "icone",
                      pageSection: "conteudo",
                      pageSubsection,
                    });
                    dispatch({
                      type: "SET_GALLERY_EXPANDED",
                      payload: {
                        galleryExpanded: !state.layout.galleryExpanded,
                      },
                    });
                  }}
                />
              )}
              <AnimatePresence>
                {gallery.map(g => {
                  const isMobile = !state.layout.isDesktop;
                  if (state.layout.selectedGalleryImage?.id !== g.id)
                    return null;
                  return (
                    <motion.div
                      key={`main-gallery-${g.id}`}
                      {...swipeHandlers}
                      initial={{
                        opacity: 0,
                        y: isMobile ? -100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        x: 0,
                      }}
                      exit={{
                        opacity: 0,
                        y: isMobile ? 100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      transition={{
                        duration: 0.001,
                      }}
                      className={styles.testeMotionDi}
                    >
                      {!!g.video && !!g.video.desktop ? (
                        <iframe
                          src={`${g.video.desktop}`}
                          title={g.title}
                          className={styles.videoHolder}
                          frameBorder="0"
                          allowFullScreen
                        />
                      ) : (
                        <Image
                          className={styles.imageHolder}
                          alt={g.alt}
                          title={g.title}
                          src={g.asset}
                          layout="fill"
                        />
                      )}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
            <Conditional
              mobile={
                <div className={styles.thumbsMobile}>
                  <Swiper
                    observer
                    observeParents
                    parallax
                    spaceBetween={2}
                    slidesPerView={2.9}
                    onSwiper={e => setController(e)}
                    controller={
                      controller ? { control: controller } : undefined
                    }
                    className={styles.swiperMobile}
                  >
                    {galleryThumbs.map((p, index) => (
                      <SwiperSlide
                        key={`gallery-thumb-${p.id}`}
                        className={styles.swiperSlide}
                      >
                        <button
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "icone",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            updateIndex(index);
                          }}
                          className={
                            state.layout.selectedGalleryImage.id === p.id
                              ? styles.active
                              : ""
                          }
                        >
                          <Image
                            alt={p.alt}
                            title={p.title}
                            src={p.thumb}
                            layout="fill"
                          />
                        </button>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              }
              desktop={
                <div className={styles.thumbsDesktop}>
                  {galleryThumbs.map((p, index) => (
                    <button
                      key={`galleryThumb-${p.id}`}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `foto-${index + 1}`,
                          elementCategory: "imagem",
                          pageSection: "conteudo",
                          pageSubsection,
                        });
                        dispatch({
                          type: "SET_GALLERY_IMAGE",
                          payload: { selectedGalleryImage: p },
                        });
                      }}
                      className={scssStyles([
                        styles.buttonTeste,
                        state.layout.selectedGalleryImage?.id === p.id
                          ? styles.active
                          : "",
                      ])}
                    >
                      <Image
                        alt={p.alt}
                        title={p.title}
                        src={p.thumb}
                        layout="fill"
                      />
                    </button>
                  ))}
                </div>
              }
            />
          </div>
          <Conditional notOn="desktop">
            <div className={styles.controls}>
              <div className={styles.bullets}>
                <Swiper
                  slidesPerView={6.5}
                  onSwiper={e => setController(e)}
                  centeredSlides
                  centeredSlidesBounds
                  controller={controller ? { control: controller } : undefined}
                  style={{
                    maxWidth: "226px",
                  }}
                >
                  {gallery.map((c, index) => {
                    const isActive =
                      state.layout.selectedGalleryImage?.id === c.id;
                    return (
                      <SwiperSlide
                        key={`bullet-${c.id}`}
                        style={{
                          width: "fit-content",
                        }}
                      >
                        <button
                          title={c.title}
                          className={scssStyles([
                            styles.bullet,
                            isActive ? styles.active : "",
                          ])}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "icone",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            dispatch({
                              type: "SET_GALLERY_IMAGE",
                              payload: { selectedGalleryImage: c },
                            });
                          }}
                        >
                          <span />
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              <div className={styles.arrowKeep}>
                <ArrowButton
                  title="arrow"
                  previous
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: "anterior",
                      elementCategory: "icone",
                      pageSection: "conteudo",
                      pageSubsection,
                    });
                    previous();
                  }}
                />
                <div className={styles.bullets}>
                  {gallery.map((c, index) => {
                    return (
                      <button
                        key={`bullet-${c.id}`}
                        title={c.title}
                        className={scssStyles([
                          styles.bullet,
                          state.layout.selectedGalleryImage?.id === c.id
                            ? styles.active
                            : "",
                        ])}
                        onClick={() => {
                          DataLayer.clickEvent({
                            element: `foto-${index + 1}`,
                            elementCategory: "icone",
                            pageSection: "conteudo",
                            pageSubsection,
                          });
                          dispatch({
                            type: "SET_GALLERY_IMAGE",
                            payload: { selectedGalleryImage: c },
                          });
                        }}
                      >
                        <span />
                      </button>
                    );
                  })}
                  {!!bulletProps.after &&
                    Array.from(Array(bulletProps.after).keys())
                      .slice(0, 2)
                      .map((_, i) => (
                        <button
                          key={`smaller-bullet-gallery-after-${i}`}
                          className={scssStyles([styles.bullet])}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${findIndex() + 2}`,
                              elementCategory: "icone",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            updateIndex(findIndex() + 2);
                          }}
                        >
                          <span />
                        </button>
                      ))}
                </div>
                <ArrowButton
                  title="Arrow"
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: "proximo",
                      elementCategory: "icone",
                      pageSection: "conteudo",
                      pageSubsection,
                    });
                    next();
                  }}
                />
              </div>
            </div>
          </Conditional>
        </div>

        <svg
          className={styles.grafismo}
          id="grafismo"
          xmlns="http://www.w3.org/2000/svg"
          width="239.436"
          height="13.212"
          viewBox="0 0 239.436 13.212"
        >
          <path
            id="Caminho_4630"
            data-name="Caminho 4630"
            d="M262.287,0,258,13.212h1.984L264.272,0Z"
            transform="translate(-83.127 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4631"
            data-name="Caminho 4631"
            d="M305.287,0,301,13.212h1.985L307.272,0Z"
            transform="translate(-96.981 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4632"
            data-name="Caminho 4632"
            d="M348.287,0,344,13.212h1.985L350.272,0Z"
            transform="translate(-110.836 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4633"
            data-name="Caminho 4633"
            d="M133.287,0,129,13.212h1.985L135.272,0Z"
            transform="translate(-41.563 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4634"
            data-name="Caminho 4634"
            d="M4.287,0,0,13.212H1.985L6.272,0Z"
            transform="translate(0 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4635"
            data-name="Caminho 4635"
            d="M47.288,0,43,13.212h1.985L49.272,0Z"
            transform="translate(-13.855 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4636"
            data-name="Caminho 4636"
            d="M90.287,0,86,13.212h1.984L92.272,0Z"
            transform="translate(-27.709 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4637"
            data-name="Caminho 4637"
            d="M176.287,0,172,13.212h1.984L178.272,0Z"
            transform="translate(-55.418 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4638"
            data-name="Caminho 4638"
            d="M219.287,0,215,13.212h1.984L221.272,0Z"
            transform="translate(-69.273 0)"
            fill="#ff1430"
          />
        </svg>
      </SectionElement>

      <AnimatePresence>
        {state.layout.galleryExpanded && <ExpandedGallery />}
      </AnimatePresence>
    </>
  );
});
