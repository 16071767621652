import { FC } from "react";

const Flag: FC<{ }> = ({
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="81"
      viewBox="0 0 186 81"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="mask"
            width="186"
            height="81"
            transform="translate(1350 612)"
            fill="#00ff58"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_de_máscara_4682"
        data-name="Grupo de máscara 4682"
        transform="translate(-1350 -612)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Caminho_8343"
          data-name="Caminho 8343"
          d="M49.77,0,5.857,135.3H26.174L70.1,0Z"
          transform="translate(1384.574 530.699)"
          fill="#edede3"
        />
        <path
          id="Caminho_8344"
          data-name="Caminho 8344"
          d="M43.92,0,0,135.3H20.353L64.266,0Z"
          transform="translate(1349.764 530.699)"
          fill="#268f52"
        />
        <path
          id="Caminho_8345"
          data-name="Caminho 8345"
          d="M55.631,0,11.711,135.3H32.043L75.963,0Z"
          transform="translate(1419.371 530.699)"
          fill="#edede3"
        />
        <path
          id="Caminho_8346"
          data-name="Caminho 8346"
          d="M61.487,0,17.567,135.3H37.892L81.819,0Z"
          transform="translate(1454.181 530.699)"
          fill="#ff1430"
        />
      </g>
    </svg>
  );
};

export default Flag;
