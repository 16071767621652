import { Conditional, SectionElement } from '@/components';
import styles from './poison-mode.module.scss';
import { POISON_MODE_DATA } from '@/data/poison-mode.data';
import { useContext, useState } from 'react';
import DataLayer from '@/utils/DataLayer';
import {dataMenuLabels, links} from "@/data/menu.data";
import { Context } from '@/context';
import { BANNER_ASSET } from "@/assets/banner.assets";
import scssStyles from '@/utils/scssStyles';

const PoisonModeSection = () => {
  const [imgSrc, setImgSrc] = useState(true);

  const handleSwitchImg = () => {
    setImgSrc(!imgSrc);
  };

    const reference = dataMenuLabels().find((c) => c.slug === "powered-by-abarth");
  const { state } = useContext(Context);
  const [isToggled, setIsToggled] = useState(false);
  const [blockToggle, setBlockToggle] = useState(false);
  const [audio] = useState(new Audio(BANNER_ASSET.audio));

  const handleToggle = () => {
    if (!blockToggle) {
      setIsToggled(!isToggled);
      audio.play();
      setBlockToggle(true);

      DataLayer.clickEvent({
        element: "ativar-modo-poison",
        elementCategory: "botao",
        pageSection: "conteudo",
        pageSubsection: "conheca-pulse-abarth",
      });

      setTimeout(() => {
        window.location.href = links.abarth;
      }, 3500);
    }
  };
    return (
      <SectionElement
        id="desktopDisplay"
        className={styles.container}
        navReference={reference}
      >
        <div className={styles.content}>
          <aside className={styles.aside}>
            <h2 className={styles.title}>{POISON_MODE_DATA.title}</h2>
            <Conditional notOn="mobile">
              <p className={styles.description}>
                {POISON_MODE_DATA.description}
              </p>
            </Conditional>
            <Conditional notOn="desktop">
              <p className={styles.description}>
                {POISON_MODE_DATA.descriptionMobile}
              </p>
            </Conditional>

            <div className={styles.buttonArea}>
              <span className={styles.buttonAreaInfo}>
                Ativar <br /> modo poison{" "}
              </span>
              <div
                className={scssStyles([
                  styles.buttonAreaWrapper,
                  isToggled ? styles.buttonAreaWrapper_on : "",
                ])}
              >
                <span className={isToggled ? styles.span_on : ""}>
                  {isToggled ? "ON" : "OFF"}
                </span>
                <button
                  onClick={handleToggle}
                  className={isToggled ? styles.on : styles.off}
                  title={isToggled ? "ON" : "OFF"}
                >
                  {state.layout.isDesktop ? (
                    <svg
                      id="Grupo_18999"
                      data-name="Grupo 18999"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.803"
                      height="27.544"
                      viewBox="0 0 24.803 27.544"
                      aria-label="on-off"
                    >
                      <path
                        id="Caminho_80"
                        data-name="Caminho 80"
                        d="M86.742,108.111l1.462-.157,1.336-.947,0,3.988-1.987.211-.814-.508Z"
                        transform="translate(-71.587 -88.311)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_81"
                        data-name="Caminho 81"
                        d="M78.181,129.505l.877.558,1.988-.182-3.055,3.485-1.2-1.026-.262-.817Z"
                        transform="translate(-63.161 -106.878)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_82"
                        data-name="Caminho 82"
                        d="M60.658,142.7l-.294.718.373,1.039,3.621-.788-1.141-1.047-.242-.828Z"
                        transform="translate(-49.817 -117.024)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_83"
                        data-name="Caminho 83"
                        d="M38.218,137.313l3.957,3.25-.346-1.145.3-.693-3-1.779Z"
                        transform="translate(-31.54 -113.019)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_84"
                        data-name="Caminho 84"
                        d="M0,23.556l1.762,8.8,8.965-4.429L9.044,25.712,3.387,29.919,2.1,27.317l2.6-.5,2.95-4.834L4.516,23.837l1.806-6.6Z"
                        transform="translate(0 -14.225)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_85"
                        data-name="Caminho 85"
                        d="M66.243,0l-8.86,1.182,6.339,2.576L60.233,5.245l5.633.594,2.087-1.883,1.285,2.6L62.46,8.492l.734,2.68,8.966-4.429Z"
                        transform="translate(-47.357 0)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_86"
                        data-name="Caminho 86"
                        d="M62.632,51.278l-1.188,1.475-1.893.048.493,3.13,4.775-2.359Z"
                        transform="translate(-49.147 -42.318)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_87"
                        data-name="Caminho 87"
                        d="M60.653,68.575l-.386.44-2.634.181-.265-.945,2.8-.266.086-1.24-.386.44-2.746.189-.252-.9,2.854-.242.152-1.275-.385.44-2.867.2-.249-.888,2.892-.254.21-1.272-.385.441-3-.04-5.127,2.553L49.211,68.5l-.584.038,1.138.606L51.725,67l.555.737L50.38,69.9l-.583.039,1.105.653,1.926-2.119.561.746-1.818,2.067-.585.038,1.039.684,1.912-2.061.59.784-1.745,1.983-.583.038,1.048.659,1.827-1.953.163.222,1.44-.2,1.361-.985-.159-.424,2.662-.263Z"
                        transform="translate(-40.131 -52.142)"
                        fill="#e72120"
                      />
                      <path
                        id="Caminho_88"
                        data-name="Caminho 88"
                        d="M30.672,110.414l-2.438,1,1.7-6.221Z"
                        transform="translate(-23.3 -86.817)"
                        fill="#e72120"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="Grupo_19000"
                      data-name="Grupo 19000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="26"
                      viewBox="0 0 32 26"
                      aria-label="on-off"
                    >
                      <g id="Grupo_19006" data-name="Grupo 19006">
                        <rect
                          id="Retângulo_5846"
                          data-name="Retângulo 5846"
                          width="32"
                          height="26"
                          rx="13"
                          fill="#fff"
                        />
                        <g
                          id="Grupo_18999"
                          data-name="Grupo 18999"
                          transform="translate(7.109 3.126)"
                        >
                          <path
                            id="Caminho_80"
                            data-name="Caminho 80"
                            d="M86.742,107.8l1.048-.113.958-.679,0,2.86-1.424.152-.584-.364Z"
                            transform="translate(-75.876 -93.602)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_81"
                            data-name="Caminho 81"
                            d="M77.715,129.505l.628.4,1.425-.131-2.19,2.5-.858-.736-.188-.586Z"
                            transform="translate(-66.945 -113.282)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_82"
                            data-name="Caminho 82"
                            d="M60.575,142.448l-.211.515.267.745,2.6-.565-.818-.75-.174-.594Z"
                            transform="translate(-52.802 -124.036)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_83"
                            data-name="Caminho 83"
                            d="M38.218,137.209l2.837,2.33-.248-.821.213-.5-2.147-1.275Z"
                            transform="translate(-33.43 -119.791)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_84"
                            data-name="Caminho 84"
                            d="M0,21.767l1.263,6.307L7.691,24.9,6.484,23.313,2.428,26.329l-.921-1.866,1.863-.358L5.485,20.64,3.238,21.969l1.295-4.732Z"
                            transform="translate(0 -15.078)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_85"
                            data-name="Caminho 85"
                            d="M63.735,0,57.383.847l4.545,1.847-2.5,1.067,4.038.426,1.5-1.35L65.882,4.7,61.023,6.089l.526,1.922,6.428-3.176Z"
                            transform="translate(-50.194 0)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_86"
                            data-name="Caminho 86"
                            d="M61.76,51.278l-.852,1.057-1.357.035.354,2.244,3.423-1.691Z"
                            transform="translate(-52.091 -44.854)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_87"
                            data-name="Caminho 87"
                            d="M57.25,67.048l-.277.316-1.889.13-.19-.677,2.008-.191.062-.889-.277.316-1.969.135-.18-.644,2.046-.174.109-.914-.276.316-2.056.141-.179-.637,2.074-.182.15-.912-.276.316-2.15-.029L50.3,65.3,49.046,67l-.419.028.816.435,1.4-1.536.4.529L49.884,68l-.418.028.793.468,1.381-1.519.4.535-1.3,1.482-.42.028.745.491,1.371-1.478.423.562-1.251,1.422-.418.027.751.473,1.31-1.4.117.159,1.032-.143.976-.706-.114-.3,1.909-.189Z"
                            transform="translate(-42.536 -55.266)"
                            fill="#e72120"
                          />
                          <path
                            id="Caminho_88"
                            data-name="Caminho 88"
                            d="M29.982,108.938l-1.748.72,1.221-4.46Z"
                            transform="translate(-24.697 -92.019)"
                            fill="#e72120"
                          />
                        </g>
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </aside>
        </div>
      </SectionElement>
    );
};

export default PoisonModeSection;
