import scssStyles from '@/utils/scssStyles';
import styles from './pulse-text-design.module.scss';
import {FC} from 'react';
import {COLORS} from '@/styles/variables';

interface ILayerProps {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    cursorPointer?: boolean;
}

export interface PulseTextDesignProps {
    className?: string;
    frontLayer?: ILayerProps;
    backLayer?: ILayerProps;
    text: string;
    textColor?: string;
    handleClick?: () => void;
    cursorPointer?: boolean;
    inverted?: boolean;
    active?: boolean;
}


const PulseTextDesign: FC<PulseTextDesignProps> = ({
                                                       text,
                                                       className,
                                                       textColor,
                                                       handleClick,
                                                       active
                                                   }) => {
    return (
        <div
            onClick={handleClick}
            className={scssStyles([styles.container, className ? className : '', active ? styles.active : ''])}
        >
            <span style={{color: textColor}}>{text}</span>
        </div>

    );
};
export default PulseTextDesign;
