import { ui } from "@/assets";
import { brandLinks } from "../constants/index";

export const dataSocial = [
  {
    id: "social-1",
    title: "Facebook",
    icon: ui.iconTFacebook,
    url: brandLinks.facebook,
  },
  {
    id: "social-2",
    title: "Youtube",
    icon: ui.iconYt,
    url: brandLinks.youtube,
  },
  {
    id: "social-3",
    title: "Twitter",
    icon: ui.iconTwitter,
    url: brandLinks.twitter,
  },
  {
    id: "social-4",
    title: "Instagram",
    icon: ui.iconInstagram,
    url: brandLinks.instagram,
  },
];
