import { ArrowButton, CTAButton, VimeoEmbed } from "@/components";
import { Chevron as ChevronComponent } from "@/components/chevron";
import { Context } from "@/context";
import { dataFooterButtonsMobile } from "@/data/menu.data";
import productDetails, {
  ProductTab,
  ProductTabSlide,
} from "@/data/product-details.data";
import DataLayer from "@/utils/DataLayer";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import getRangeOfEntries from "@/utils/getRangeOfEntries";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import React, { FC, Fragment, useContext, useRef, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./swiper-product-details-mobile.module.scss";
import { NewCta } from "@/components/new-cta";
import { Chevron, ChevronRight } from "@/components/SvgComponents";

SwiperCore.use([A11y, Keyboard, Thumbs]);

interface SlideProps {
  slide: ProductTabSlide;
}

interface ISetReference {
  setReference: (tab?: ProductTab) => void;
}
const pageSubsection = "tudo-sobre-fastback-abarth";

const Slide: FC<SlideProps> = observer(({ slide }) => {
  return (
    <div className={styles.slideDetails}>
      <h4>{slide.title}</h4>
      <div className={styles.slideDescription}>
        {slide.descriptionMobile}
        {!!slide.linkBtn && (
          <div className={styles.ctas}>
            <a
              href={slide.linkBtn}
              className={styles.buy}
              target={"_blank"}
              onClick={() => {
                DataLayer.clickEvent({
                  elementCategory: "cta",
                  element: "eu-quero",
                  pageSection: "tudo-sobre",
                  pageSubsection: "acessorios",
                });
              }}
            >
              {slide.labelBtn}
              <ChevronRight />
            </a>
          </div>
        )}
      </div>
    </div>
  );
});

interface TabProps extends ISetReference {
  tab: ProductTab;
  rerender: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tab: FC<TabProps> = ({ tab, rerender, setReference }) => {
  const [touchLocation, setTouchLocation] = useState<PointerEvent["clientX"]>();
  const { state, dispatch } = useContext(Context);
  const bulletProps = getRangeOfEntries(
    state.layout.productDetailsMobileSwiperController[tab.id]?.activeIndex,
    tab.children
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const activeIndex =
    state.layout.productDetailsMobileSwiperController[tab.id]?.activeIndex ?? 0;

  const handleSelectedDetailTab = (tab: null | ProductTab): void => {
    if (tab) {
      dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });
    }

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };

  const handleSwiper = (e: SwiperCore | null, tabId: string): void => {
    dispatch({
      type: "SET_PRODUCT_DETAILS_MOBILE_SWIPER_CONTROLLER",
      payload: {
        productDetailsMobileSwiperController: e,
        tabId,
      },
    });
  };

  const activeSlide = tab.children[activeIndex];

  return (
    <>
      <button
        className={scssStyles([
          "accordion",
          "noFocus",
          styles.accordionTopic,
          state.layout.selectedDetailTab === tab.id ? styles.active : "",
          state.layout.selectedDetailTab === tab.id ? "active" : "",
        ])}
        title={
          state.layout.selectedDetailTab === tab.id ? "Fechar" : "Expandir"
        }
        onClick={() => {
          DataLayer.clickEvent({
            element: tab.title,
            elementCategory: "botao",
            pageSection: "conteudo",
            pageSubsection,
          });
          handleSelectedDetailTab(
            state.layout.selectedDetailTab === tab.id ? null : tab
          );
        }}
      >
        <div className="chevron">
          <Chevron />
        </div>
        <h3>{tab.title}</h3>
      </button>
      <div
        id={`panel-${tab.id}`}
        className={scssStyles(["panel", styles.topicAnswer])}
      >
        <div id={`panel-body-${tab.id}`}>
          <div className={styles.slideContainer}>
            {activeSlide.vimeoId?.mobile ? (
              <div className={styles.videoWrapper}>
                <div className={styles.videoContainer}>
                  <VimeoEmbed
                    className={styles.videoHolder}
                    id={activeSlide.vimeoId.mobile}
                    title=""
                  />
                </div>
              </div>
            ) : (
              <div className={styles.imageHolder}>
                <Image
                  alt={activeSlide.altImage}
                  title={activeSlide.titleImage}
                  src={activeSlide.image}
                  layout="fill"
                />
              </div>
            )}
          </div>

          <div className={styles.controls}>
        
            <ArrowButton
              className={styles.arrowClass}
              title="Arrow"
              previous
              disabled={
                state.layout.productDetailsMobileSwiperController[tab.id]
                  ?.activeIndex === 0
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "anterior",
                  elementCategory: "seta",
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.productDetailsMobileSwiperController[
                  tab.id
                ]?.slidePrev();
              }}
            />
            <div className={styles.bullets}>
              {!!bulletProps.before &&
                Array.from(Array(bulletProps.before).keys()).map((_, i) => (
                  <button
                    key={`smaller-bullet-ProductDetails-before-${i}`}
                    className={scssStyles([styles.bullet])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${activeIndex - 2}`,
                        elementCategory: "icone",
                        pageSection: "conteudo",
                        pageSubsection,
                      });
                      if (
                        state.layout.productDetailsMobileSwiperController &&
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]
                      ) {
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]?.slideTo(activeIndex - 2);
                      }
                    }}
                  >
                    <span />
                  </button>
                ))}
              {tab.children.map((c, index) => {
                return (
                  <button
                    key={`bullet-s-j-${c.id}`}
                    title={c.title}
                    className={scssStyles([
                      styles.bullet,
                      state.layout.productDetailsMobileSwiperController[tab.id]
                        ?.activeIndex === index
                        ? styles.active
                        : "",
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${index + 1}`,
                        elementCategory: "icone",
                        pageSection: pageSubsection,
                        pageSubsection: tab.title,
                      });
                      if (
                        c.index &&
                        state.layout.productDetailsMobileSwiperController &&
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]
                      ) {
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]?.slideTo(index);
                      }
                    }}
                  >
                    <span />
                  </button>
                );
              })}
            </div>
            {/* <ChevronComponent
              chevronColor="#fff"
              chevronAngule={180}
              className={styles.arrowClass}
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "proximo",
                  elementCategory: "seta",
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.productDetailsMobileSwiperController[
                  tab.id
                ]?.slideNext();
              }}
            /> */}
            <ArrowButton
              className={styles.arrowClass}
              title="Arrow"
              disabled={
                (state.layout.productDetailsMobileSwiperController[tab.id]
                  ?.activeIndex ?? tab.children.length - 1) >=
                tab.children.length - 1
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "proximo",
                  elementCategory: "seta",
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.productDetailsMobileSwiperController[
                  tab.id
                ]?.slideNext();
              }}
            />
          </div>

          <Swiper
            observer
            observeParents
            parallax
            onSwiper={e => handleSwiper(e, tab.id)}
            controller={
              state.layout.productDetailsMobileSwiperController[tab.id] != null
                ? {
                    control: state.layout.productDetailsMobileSwiperController[
                      tab.id
                    ] as SwiperCore,
                  }
                : undefined
            }
            onSlideChange={e => {
              const index = e.activeIndex;
              const tabSelected = productDetails.find(p => p.id === tab.id);
              handleSwiper(e, tab.id);
              setReference(tabSelected);
              rerender(r => !r);
            }}
            onTouchStart={(_, event) => {
              const clientX = getClientXFromDifferentEvents(event);
              setTouchLocation(clientX);
            }}
            onTouchEnd={(_, event) => {
              const clientX = getClientXFromDifferentEvents(event);
              const touchLocation2 = touchLocation ?? 0;

              touchLocation2 > clientX &&
                DataLayer.swipeEvent({
                  element: "proximo",
                  elementCategory: "imagem",
                  pageSection: pageSubsection,
                  pageSubsection: tab.title,
                });

              touchLocation2 < clientX &&
                DataLayer.swipeEvent({
                  element: "anterior",
                  elementCategory: "imagem",
                  pageSection: pageSubsection,
                  pageSubsection: tab.title,
                });
            }}
          >
            {tab.children.map(slide => (
              <SwiperSlide key={`${tab.id}-x-0-${slide.id}`}>
                <Slide slide={{ ...slide, tabId: tab.id }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

const layerEvent = (element: any) => {
  DataLayer.clickEvent({
    elementCategory: "cta",
    element,
    pageSection: "conteudo",
    pageSubsection: `vamos-negociar`,
  });
};

const SwipersMobile: FC<ISetReference> = ({ setReference }) => {
  const [, rerender] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.containerHOLDER}>
        {productDetails.map((tab) => (
          <Fragment key={`sw-frag-${tab.id}`}>
            <Tab tab={tab} rerender={rerender} setReference={setReference} />
          </Fragment>
        ))}
      </div>
      <div className={styles.buttonsHolderContainer}>
        {!!dataFooterButtonsMobile.length && (
          <div className={styles.buttonsHolder}>
            <h2 className={styles.lets_business}>
              VAMOS <b>NEGOCIAR?</b>
            </h2>
            {dataFooterButtonsMobile.map((item) => {
              return (
                <a target="_blank" href={item.url}>
                  <CTAButton
                    width={"100%"}
                    href={item.url}
                    text={item.label}
                    title={item.label}
                    handleClick={() => layerEvent(item.label)}
                  />{" "}
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SwipersMobile;
