import { CTAButton,  SectionElement } from '@/components';
import { Context } from '@/context';
import { EComparativeStatus } from '@/context/comparative.context';
import { COMPARATIVE_DATA } from '@/data/comparative.data';
import { dataMenuLabels } from '@/data/menu.data';
import {
  VERSIONS_DATA,
  VERSION_YEAR_BASE,
  getVersionsPriceURL,
} from '@/data/versions.data';
import DataLayer from '@/utils/DataLayer';
import { PREFIX } from '@utils/imagePrefixes';
import scssStyles from '@utils/scssStyles';
import { FC, useContext, useEffect } from 'react';
import styles from './Comparative.module.scss';
import { Chevron } from '@/components/SvgComponents';

const Comparative: FC = () => {
  const { state, dispatch, COMPARATIVE_CONTEXT } = useContext(Context);

  const referenceVersion = dataMenuLabels().find((c) => c.slug === 'versoes');
  const index = VERSIONS_DATA.findIndex(
    (c) => c.slug === state.layout.currentVersion.slug
  );
  const suffix = VERSIONS_DATA[index ?? 0]?.slug;
  const handleClose = () => {
    COMPARATIVE_CONTEXT.set_comparative_status(EComparativeStatus.CLOSE);
    dispatch({
      type: 'SCROLL_TO_REFERENCE',
      payload: { reference: dataMenuLabels()[2] },
    });
    dispatch({
      type: 'SET_CURRENTLY_VISIBLE_NAV',
      payload: {
        view: referenceVersion,
        suffix: suffix,
      },
    });
  };

  useEffect(() => {
    COMPARATIVE_DATA.map(async (item, i) => {
      const dataPrice = await fetch(
        getVersionsPriceURL(item.code, VERSION_YEAR_BASE)
      );
      const priceResponse = await dataPrice.json();

      item.price = COMPARATIVE_DATA[i].price =
        priceResponse.price.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        });
    });
  }, []);
  return (
    <>
      {COMPARATIVE_CONTEXT.get_comparative_status ===
        EComparativeStatus.OPEN && (
        <SectionElement
          id="comparative"
          className={styles.sectionContainer}
          navReference={dataMenuLabels()[5]}
        >
          <div id="comparativo" className={styles.container}>
            {/* <div className={styles.container}> */}

            <div className={styles.sectionArea}>
              <div
                onClick={() => {
                  DataLayer.clickEvent({
                    element: 'fechar comparativo',
                    elementCategory: 'botao',
                    pageSection: 'conteudo',
                    pageSubsection: 'comparativo',
                  });
                  handleClose();
                }}
                className={styles.close_icon}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.211"
                  height="17.211"
                  viewBox="0 0 17.211 17.211"
                >
                  <path
                    id="Caminho_250"
                    data-name="Caminho 250"
                    d="M0,.427,12.985.174,21.874,0"
                    transform="translate(1.023 0.721) rotate(45)"
                    fill="none"
                    stroke="#e5b159"
                    strokeWidth="2"
                  />
                  <path
                    id="Caminho_4827"
                    data-name="Caminho 4827"
                    d="M0,.427,12.985.174,21.874,0"
                    transform="translate(0.721 16.188) rotate(-45)"
                    fill="none"
                    stroke="#e5b159"
                    strokeWidth="2"
                  />
                </svg>
              </div>

              <div
                className={styles.container}
                style={{
                  gridTemplateColumns: `repeat(${
                    state.layout.isDesktop ? 3 : 1
                  }, 1fr)`,
                }}
              >
                {COMPARATIVE_DATA.map((item, index) => {
                  return (
                    <div
                      className={styles.content}
                      key={item.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#F2F2F2",
                      }}
                    >
                      <div
                        className={scssStyles([
                          styles.header,
                          styles.paddingArea,
                        ])}
                      >
                        <span>{item.versionName}</span>
                        <br />
                        <small>A partir de ${item.price}</small>
                      </div>
                      <div className={styles.features}>
                        {item.features.map(feature => {
                          return (
                            <div
                              key={feature.id}
                              className={styles.featuresContainer}
                            >
                              <div className={styles.headerFeature}>
                                <img
                                  src={feature.icon}
                                  alt={feature.title}
                                  className={styles.featureLogo}
                                />
                                <h4 className={styles.featureHeader}>
                                  {feature.title}
                                </h4>
                              </div>
                              <div className={styles.featureContent}>
                                {feature.featuresContent.map(
                                  (content, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={scssStyles([
                                          styles.contentHeader,
                                          content.contentIcon
                                            ? styles.contentIcon
                                            : "",
                                        ])}
                                      >
                                        <strong>{content.title}</strong>
                                        {content.contentIcon && (
                                          <img
                                            src={content.contentIcon}
                                            alt={content.title}
                                          />
                                        )}
                                        {content.hasCheck ? (
                                          <div className={styles.check}>
                                            <img
                                              src={`${PREFIX}Comparative/check.svg`}
                                              alt={content.title}
                                            />
                                            <p>{content.content}</p>
                                          </div>
                                        ) : (
                                          <p>{content.content}</p>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* 
                      <a
                        className={styles.download}
                        download={true}
                        href={item.pdf}
                        onClick={() => {
                          DataLayer.clickEvent({
                            elementCategory: 'botao',
                            element: 'Ficha Técnica',
                            pageSection: 'conteudo',
                            pageSubsection: 'comparativo',
                          });
                        }}
                      >
                        <imgFiat Pulse 2024
                          src={`${PREFIX}Comparative/download.svg`}
                          alt={'download'}
                        />
                        <p>Ficha técnica</p>
                      </a> */}

                      <div className={styles.ctas}>
                        {item.cta.map(cta => {
                          return (
                            <CTAButton
                              key={`menu-cta-${cta.id}`}
                              href={cta.url}
                              className={scssStyles([
                                styles.ctaButton,
                                cta.id === "ctaButton-1" ? styles.interest : "",
                              ])}
                              text={`${cta.text}`}
                              width={state.layout.isDesktop ? "80%" : "100%"}
                              title={cta.label}
                              backgroundColor={cta.backgroundColor}
                              color={cta.color}
                              excludeArrow={cta.excludeArrow}
                              handleClick={() => {
                                DataLayer.clickEvent({
                                  element: `${cta.label} ${item.versionName}`,
                                  elementCategory: "botao",
                                  pageSection: "conteudo",
                                  pageSubsection: "comparativo",
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className={styles.close}
                onClick={() => {
                  DataLayer.clickEvent({
                    element: 'fechar comparativo',
                    elementCategory: 'botao',
                    pageSection: 'conteudo',
                    pageSubsection: 'comparativo',
                  });
                  handleClose();
                }}
              >
                <h3>FECHAR COMPARADOR</h3>
                <div>
                  <Chevron direction={'up'} />
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </SectionElement>
      )}
    </>
  );
};

export default Comparative;
