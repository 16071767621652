import { ComparativeProps } from '@/models';
import { COMPRE_TITLE, links, MONTE_TITLE } from '@/data/menu.data';
import { PREFIX } from '@utils/imagePrefixes';
import { VERSIONS_DATA, VERSIONS_REFERENCE } from './versions.data';
import { COLORS } from '@/styles/variables';

const year_base = 2025;

const ICONS = {
  MOTOR: `${PREFIX}Comparative/motor.svg`,
  INTERIOR: `${PREFIX}Comparative/interior.svg`,
  TECNOLOGIA: `${PREFIX}Comparative/tecnologia.svg`,
  DESIGN: `${PREFIX}Comparative/design.svg`,
  SECURITY: `${PREFIX}Comparative/seguranca.svg`,
};
export const COMPARATIVE_DATA: ComparativeProps[] = [
  {
    id: 'comparative-00',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    price: VERSIONS_DATA[0].price,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: '1.3 Firefly Flex',
          },
          {
            title: 'Potência e Torque',
            content: '107 cv e 134 Nm',
          },
          {
            title: 'Câmbio Manual',
            content: '5 marchas',
          },
          {
            title: 'ASR e TC+',
            content: '(Controle eletrônico de tração)',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 8,4”',
            content: 'Espelhamento sem fio (Apple Car Play e Android Auto)',
          },
          {
            title: 'Painel de instrumentos',
            content: '3,5 em TFT',
          },
          {
            title: 'Connect////Me ',
            content: 'Serviços Conectados (Opcional)',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em tecido',
          },
          {
            title: 'Volante ',
            content: 'Com regulagem de altura',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas de aço',
            content: '16”',
          },
          {
            title: 'Grade Preta ',
            content: 'Com Logo Fiat e Fiat Flag',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu Drive 1.3 MT',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu Drive 1.3 MT',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
  {
    id: 'comparative-01',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: '1.3 Firefly Flex',
          },
          {
            title: 'Potência e Torque',
            content: '107 cv e 134 Nm',
          },
          {
            title: 'Câmbio automático CVT',
            content: '7 marchas simuladas',
          },
          {
            title: 'ASR e TC+',
            content: '(Controle eletrônico de tração)',
          },
          {
            title: 'Modo Sport ',
            content: 'Acionamento no volante',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 8,4”',
            content: 'Espelhamento sem fio (Apple Car Play e Android Auto)',
          },
          {
            title: 'Painel de instrumentos',
            content: '3,5 em TFT',
          },
          {
            title: 'Connect //// Me ',
            content: 'Serviços Conectados (Opcional)',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em tecido',
          },
          {
            title: 'Volante ',
            content: 'Com regulagem de altura',
          },
          {
            title: 'Console central ',
            content: 'Com comandos do carro, apoio de braço e 18 porta-objetos',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas de aço',
            content: '16”',
          },
          {
            title: 'Grade Preta ',
            content: 'Com Logo Fiat e Fiat Flag',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu Drive 1.3 AT',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu Drive 1.3 AT',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
  {
    id: 'comparative-02',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: '1.3 Firefly Flex',
          },
          {
            title: 'Potência e Torque',
            content: '107 cv e 134 Nm',
          },
          {
            title: 'Câmbio Manual',
            content: '5 marchas',
          },
          {
            title: 'ASR e TC+',
            content: '(Controle eletrônico de tração)',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 10,1”',
            content:
              'Espelhamento sem fio (Apple Car Play e Android Auto) Navegação embarcada (GPS)',
          },
          {
            title: 'Painel de instrumentos',
            content: '3,5 em TFT',
          },
          {
            title: 'Connect //// Me ',
            content: 'Serviços Conectados (Opcional)',
          },
          {
            title: 'Keyless Entry’n Go',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em tecido',
          },
          {
            title: 'Volante ',
            content: 'Com regulagem de altura',
          },
          {
            title: 'Console central',
            content: 'Com comandos do carro, apoio de braço e 18 porta-objetos',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas de Liga-Leve Escurecidas',
            content: '16”',
          },
          {
            title: 'Grade Preta ',
            content:
              'Com Logo Fiat Escurecido, Fiat Flag e Badge S-Design na lateral',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu S-Design',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu S-Design',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
  {
    id: 'comparative-03',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: 'Turbo 200 Flex',
          },
          {
            title: 'Potência e Torque',
            content: '130 cv e 200 Nm',
          },
          {
            title: 'Câmbio automático CVT',
            content: '7 marchas simuladas',
          },
          {
            title: 'ASR e TC+',
            content: '(Controle eletrônico de tração)',
          },
          {
            title: 'Modo Sport ',
            content: 'Acionamento no volante',
          },
          {
            title: 'Paddle Shifters ',
            content: '(Câmbio borboleta no volante)',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 8,4”',
            content: 'Espelhamento sem fio (Apple Car Play e Android Auto)',
          },
          {
            title: 'Painel de instrumentos',
            content: '3,5 em TFT',
          },
          {
            title: 'Connect //// Me ',
            content: 'Serviços Conectados (Opcional)',
          },
          {
            title: 'Keyless Entry’n Go',
          },
          {
            title: 'Wireless Charger',
            content: '(Carregador do Celular por Indução)',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em tecido',
            content: 'Bipartido 60/40 com assentos rebatíveis (traseiro) ',
          },
          {
            title: 'Volante em couro ',
            content: 'Com regulagem de altura',
          },
          {
            title: 'Console central ',
            content: 'Com comandos do carro, apoio de braço e 18 porta-objetos',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas de aço',
            content: '16”',
          },
          {
            title: 'Grade Preta ',
            content: 'Com Logo Fiat e Fiat Flag',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu Audace Turbo',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu Audace Turbo',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
  {
    id: 'comparative-04',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: 'Turbo 200 Flex',
          },
          {
            title: 'Potência e Torque',
            content: '130 cv e 200 Nm',
          },
          {
            title: 'Câmbio automático CVT',
            content: '7 marchas simuladas',
          },
          {
            title: 'ASR e TC+',
            content: '(Controle eletrônico de tração)',
          },
          {
            title: 'Modo Sport ',
            content: 'Acionamento no volante',
          },
          {
            title: 'Paddle Shifters ',
            content: '(Câmbio borboleta no volante)',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 10,1”',
            content:
              'Espelhamento sem fio (Apple Car Play e Android Auto) Navegação embarcada (GPS)',
          },
          {
            title: 'Painel de instrumentos',
            content: '7” Full Digital',
          },
          {
            title: 'Connect //// Me ',
            content: 'Serviços Conectados (Opcional)',
          },
          {
            title: 'Keyless Entry’n Go',
          },
          {
            title: 'Wireless Charger',
            content: '(Carregador do Celular por Indução)',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em couro',
            content: 'Bipartido 60/40 com assentos rebatíveis (traseiro)  ',
          },
          {
            title: 'Volante em couro ',
            content: 'Com regulagem de altura',
          },
          {
            title: 'Console central ',
            content: 'Com comandos do carro, apoio de braço e 18 porta-objetos',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas de aço',
            content: '17”',
          },
          {
            title: 'Grade Preta ',
            content: 'Com Logo Fiat e Fiat Flag',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu Impetus Turbo',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu Impetus Turbo',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
  {
    id: 'comparative-05',
    code: VERSIONS_REFERENCE[0].code,
    versionName: VERSIONS_REFERENCE[0].title,
    features: [
      {
        id: 'feature-00',
        title: 'Performance',
        icon: ICONS.MOTOR,
        featuresContent: [
          {
            title: 'Motorização',
            content: 'Turbo 200 Flex',
          },
          {
            title: 'Potência e Torque',
            content: '185 cv e 270 Nm ',
          },
          {
            title: 'Câmbio automático',
            content: '6 marchas',
          },
          {
            title: 'ASR',
            content: '(Controle eletrônico de tração)',
          },
          {
            title: 'Modo Poison ',
            content: 'Acionamento no volante',
          },
          {
            title: 'Paddle Shifters ',
            content: '(Câmbio borboleta no volante)',
          },
        ],
      },
      {
        id: 'feature-01',
        title: 'Tecnologia',
        icon: ICONS.TECNOLOGIA,
        featuresContent: [
          {
            title: 'Central multimídia de 8,4”',
            content:
              'Espelhamento sem fio (Apple Car Play e Android Auto) Navegação embarcada (GPS)',
          },
          {
            title: 'Painel de instrumentos',
            content: '7” Full Digital',
          },
          {
            title: 'Connect //// Me ',
            content: 'Serviços Conectados',
          },
          {
            title: 'Keyless Entry’n Go',
          },
          {
            title: 'Wireless Charger',
            content: '(Carregador do Celular por Indução)',
          },
        ],
      },
      {
        id: 'feature-02',
        title: 'Interior',
        icon: ICONS.INTERIOR,
        featuresContent: [
          {
            title: 'Bancos em couro',
            content: 'Bipartido 60/40 com assentos rebatíveis (traseiro)  ',
          },
          {
            title: 'Volante em couro ',
            content: 'Com regulagem de altura',
          },
          {
            title: 'Console central ',
            content: 'Com comandos do carro, apoio de braço e 18 porta-objetos',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Design',

        icon: `${PREFIX}Comparative/design.svg`,
        featuresContent: [
          {
            title: 'Rodas Esportivas com pneus 215/50',
            content: '17”',
          },
          {
            title: 'Grade Preta ',
            content: 'Com Logo Abarth e Fiat Flag ',
          },
        ],
      },
      {
        id: 'feature-03',
        title: 'Segurança',

        icon: ICONS.SECURITY,
        featuresContent: [
          {
            content: '4 airbags',
            hasCheck: true,
          },
          {
            content: 'Alerta de frenagem de emergência',
            hasCheck: true,
          },
          {
            content: 'Monitoramento de pressão dos pneus',
            hasCheck: true,
          },
          {
            content: 'Freio de estacionamento eletrônico',
            hasCheck: true,
          },
        ],
      },
    ],
    cta: [
      {
        id: 'ctaButton-1',
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=${year_base}#versao`,
        backgroundColor: COLORS.VERSIONS.RED,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Monte o seu Fastback Abarth',
      },
      {
        id: 'ctaButton-0',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: COLORS.VERSIONS.MARINE,
        color: COLORS.VERSIONS.DEFAULT,
        excludeArrow: true,
        text: 'Compre o seu Fastback Abarth',
      },
    ],
    pdf: '/images/Comparative/pdf/Sport Flex T270 AT6 FWD PL7.pdf',
  },
];
