import { Cards, Display, SectionElement } from '@/components';
import { dataHero, IHero } from '@/data/hero.data';
import { dataMenuLabels } from '@/data/menu.data';
import scssStyles from '@/utils/scssStyles';
import React, { FC } from 'react';
import styles from './HeroDesktop.module.scss';
import { PREFIX } from '@/utils/imagePrefixes';
import NewFastback from '@/components/SvgComponents/NewFastback';
import { title } from 'process';

export const HeroDesktop: FC = () => {
  const previous = React.useRef<(() => void) | null>(null);
  const next = React.useRef<(() => void) | null>(null);

  const items: IHero[] | any = [];

  dataHero.map((item, index) =>
    items.push({
      body: (
        <div
          className={scssStyles([styles.slides])}
          title={item.titleTag}
        >
          <div className={styles.content}>
            <div
              className={scssStyles([
                index < 1
                  ? styles.first_descriptionContent
                  : styles.descriptionContent,
              ])}
            >
              {item.id === "0" ? (
                <div className={styles.svgContent}>
                  <NewFastback />
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200.173"
                    height="11.046"
                    viewBox="0 0 200.173 11.046"
                  >
                    <g
                      id="Grupo_4238"
                      data-name="Grupo 4238"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_4630"
                        data-name="Caminho 4630"
                        d="M261.584,0,258,11.046h1.659L263.244,0Z"
                        transform="translate(-111.803)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4631"
                        data-name="Caminho 4631"
                        d="M304.584,0,301,11.046h1.659L306.244,0Z"
                        transform="translate(-130.436)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4632"
                        data-name="Caminho 4632"
                        d="M347.584,0,344,11.046h1.659L349.243,0Z"
                        transform="translate(-149.07)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4633"
                        data-name="Caminho 4633"
                        d="M132.584,0,129,11.046h1.659L134.243,0Z"
                        transform="translate(-55.901)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4634"
                        data-name="Caminho 4634"
                        d="M3.584,0,0,11.046H1.659L5.243,0Z"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4635"
                        data-name="Caminho 4635"
                        d="M46.584,0,43,11.046H44.66L48.244,0Z"
                        transform="translate(-18.634)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4636"
                        data-name="Caminho 4636"
                        d="M89.584,0,86,11.046h1.659L91.244,0Z"
                        transform="translate(-37.268)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4637"
                        data-name="Caminho 4637"
                        d="M175.584,0,172,11.046h1.659L177.243,0Z"
                        transform="translate(-74.535)"
                        fill="#ffb80f"
                      />
                      <path
                        id="Caminho_4638"
                        data-name="Caminho 4638"
                        d="M218.584,0,215,11.046h1.659L220.244,0Z"
                        transform="translate(-93.169)"
                        fill="#ffb80f"
                      />
                    </g>
                  </svg>
                  <div className={styles.tlt}>{item.title}</div>
                </>
              )}
              <p>{item.description}</p>
            </div>
          </div>
        </div>
      ),
      i: index,
      background: {
        src: item.src.desktop,
        srcM: item.src.mobile,
        alt: item.altTag,
        title: item.titleTag,
      },
      videoProps: {
        src: item.src.desktop,
        autoPlay: true,
        loop: true,
        muted: true,
      },
    })
  );

  const dadosFiltrados = dataHero
    .map((d) => ({
      ...d,
      src: d.cardSrc,
      title: d.cardTitle,
    }))
    .filter((d) => d.id !== '0');
  return (
    <SectionElement
      id="desktopDisplay"
      className={styles.container}
      navReference={dataMenuLabels()[0]}
      handleKeyDown={(e, isVisible) => {
        if (isVisible) {
          if (e.key === 'ArrowLeft') {
            previous?.current && previous?.current();
          } else if (e.key === 'ArrowRight') {
            next?.current && next?.current();
          }
        }
      }}
    >
      <Display
        previousEvent={(e) => {
          previous.current = e;
        }}
        nextEvent={(e) => {
          next.current = e;
        }}
        items={items}
      />
      <Cards
        items={dadosFiltrados.map((d) => ({
          ...d,
          src: d.cardSrc,
          title: d.cardTitle,
        }))}
      />
    </SectionElement>
  );
};
