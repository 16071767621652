import { HERO_ASSETS } from '@/assets/hero.assets';

export interface IHero {
  id: string;
  title: string | JSX.Element;
  titleStories: string | JSX.Element;
  description: string | JSX.Element;
  descriptionStories: string | JSX.Element;
  cardTitle: string;
  cardSrc: string;
  src: { desktop: string; mobile: string };
  titleTag: string;
  altTag: string;
}

export const dataHero: IHero[] = [
  {
    id: '0',
    title: (
      <>
        <h1>FIAT FASTBACK</h1>
      </>
    ),
    titleStories: <></>,
    description: <></>,
    descriptionStories: <></>,
    titleTag:
      'Liberte o veneno que vive em você',
    altTag:
      'Fiat Fastback vermelho acelera na pista e forma um escorpião de fumaça.',
    cardTitle: '',
    cardSrc: HERO_ASSETS.desktop.slide1,
    src: {
      desktop: HERO_ASSETS.desktop.slide1,
      mobile: HERO_ASSETS.mobile.slide1,
    },
  },
  // Design Coupé
  {
    id: '1',
    title: (
      <>
        Design<br/>coupé
      </>
    ),
    titleStories: (
      <>
        Design<br/>coupé
      </>
    ),
    description: (
      <>
          A combinação perfeita de um SUV com<br/>grande altura em relação ao solo com um<br/>COUPÉ de teto com caída veloz em<br/>direção a traseira.
      </>
    ),
    descriptionStories: (
      <>
          A combinação perfeita de um SUV com<br/>grande altura em relação ao solo com um<br/>COUPÉ de teto com caída veloz em<br/>direção a traseira.
      </>
    ),
    altTag:
      'Fastback branco vista traseira com destaque para o teto coupé e a traseira esportiva.',
    titleTag:
      'Design Coupé',
    cardTitle: 'Design SUV Coupé',
    cardSrc: HERO_ASSETS.desktop.slide2,
    src: {
      desktop: HERO_ASSETS.desktop.slide2,
      mobile: HERO_ASSETS.mobile.slide2,
    },
  },
  // Performance
  {
    id: '2',
    title: <>PERFORMANCE</>,
    titleStories: <>PERFORMANCE</>,
    description: (
        <>
          Equipado apenas com motores turbo:<br/>potência aliada a eficiência energética.
          <br />
          <small>
            Vá a uma Concessionária Fiat e consulte as características
            dos modelos disponíveis Imagens meramente ilustrativas.
            Cenas produzidas em ambiente controlado e por
            profissionais capacitados. Não tente reproduzir e respeite
            a legislação de trânsito"Feature Powered By Abarth no
            destaque principal.
          </small>
        </>
    ),
    descriptionStories: (
        <>
          Equipado apenas com motores turbo:<br/>potência aliada a eficiência energética.
          <br/>
          <small>
            Vá a uma Concessionária Fiat e consulte as
            características dos modelos disponíveis Imagens
            meramente ilustrativas. Cenas produzidas em ambiente
            controlado e por profissionais capacitados. Não tente
            reproduzir e respeite a legislação de trânsito Feature
            Powered By Abarth no destaque principal
          </small>
        </>
    ),
    altTag:
        'Motor do Fastback com destaque para o emblema de escorpião e a potência do motor.',
    titleTag:
        'Performance',
    cardTitle: 'Performance',
    cardSrc: HERO_ASSETS.desktop.slide3,

    src: {
      desktop: HERO_ASSETS.desktop.video[0],
      mobile: HERO_ASSETS.mobile.video[0],
    },
  },
  // ESPAÇO
  {
    id: '3',
    title: <>Espaço do<br/>Porta-malas</>,
    titleStories: <>Espaço do<br/>Porta-malas</>,
    description: (
        <>
        Simplesmente o maior porta-malas da<br/>categoria. São 600L de capacidade<br/>que proporcionam infinitas<br/>possibilidades.
        </>
    ),
    descriptionStories: (
      <>
        Simplesmente o maior porta-malas da<br/>categoria. São 600L de capacidade<br/>que proporcionam infinitas<br/>possibilidades.
      </>
    ),
    altTag:
      'Porta-malas do Fastback com destaque para a capacidade de 600L e o emblema de escorpião.',
    titleTag:
      'ESPAÇO',
    cardTitle: 'ESPAÇO',
    cardSrc: HERO_ASSETS.desktop.slide4,

    src: {
      desktop: HERO_ASSETS.desktop.slide4,
      mobile: HERO_ASSETS.mobile.slide4,
    },
  },
  // Powered By Abarth
  {
    id: '4',
    title: <>POWERED<br/>BY ABARTH</>,
    titleStories: <>POWERED<br/>BY ABARTH</>,
    description: (
      <>
        A exclusividade de um motor da divisão<br/>Abarth: uma incrível potência de até 185CV<br/>com a super força de torque<br/>de até 270NM.
      </>
    ),
    descriptionStories: (
      <>
        A exclusividade de um motor da divisão<br/>Abarth: uma incrível potência de até 185CV<br/>com a super força de torque<br/>de até 270NM.
      </>
    ),
    altTag:
      'Motor do Fastback com destaque para o emblema de escorpião e a potência do motor.',
    titleTag:
      'POWERED BY ABARTH',
    cardTitle: 'POWERED BY ABARTH',
    cardSrc: HERO_ASSETS.desktop.slide5,

    src: {
      desktop: HERO_ASSETS.desktop.slide5,
      mobile: HERO_ASSETS.mobile.slide5,
    },
  },
];
