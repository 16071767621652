const PREFIX = `${process.env.BASE_PREFIX}images/non-default/poison-mode/`;

export const POISON_MODE_DATA = {
  title: (
    <>
        CONHEÇA SEU LADO
      <br />
        ESPORTIVO COM
        <br/>
        FASTBACK ABARTH
    </>
  ),
  description: (
    <>
        Ative o Modo Poison, ouça o som do motor de Fastback Abarth e conheça os detalhes do Fiat Fastback mais esportivo.
    </>
  ),
  descriptionMobile: (
    <>
        Ative o Modo Poison, ouça o som do motor de Fastback Abarth e conheça os detalhes do Fiat Fastback mais esportivo.
    </>
  ),
  link: "https://fastback.fiat.com.br/",
  src: `${PREFIX}modo-poison@3x.webp`,
  poisonModeOn: `${PREFIX}poisonModeOn.svg`,
  poisonModeOff: `${PREFIX}poisonModeOff.svg`,
};
