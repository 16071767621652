import { SectionElement } from '@/components';
import { dataMenuLabels } from '@/data/menu.data';
import { observer } from 'mobx-react-lite';
import { FC, useContext } from 'react';

import { Context } from '@/context';
import Version from '@/components/version-component';
import { VERSIONS_DATA } from '@/data/versions.data';

const reference = dataMenuLabels().find((c) => c.slug === 'versoes');

const VersionsSection: FC = observer(() => {
  const { state, dispatch, VERSION_CONTEXT } = useContext(Context);

  return (
    <SectionElement
      id="versions"
      navReference={reference}
      onVisibilityChange={(load) => {
        const index = VERSIONS_DATA.findIndex(
          (c) => c.slug === VERSION_CONTEXT.get_version.slug
        );

        if (load) {
          const suffix = VERSIONS_DATA[index ?? 0]?.slug;
          setTimeout(() => {
            if (reference && state.layout.currentTab && suffix) {
              dispatch({
                type: 'SET_CURRENTLY_VISIBLE_NAV',
                payload: {
                  view: reference,
                  suffix: suffix,
                },
              });
            }
          }, 100);
        }
      }}
    >
      <Version />
    </SectionElement>
  );
});

export default VersionsSection;
