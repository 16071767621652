import { Context } from '@/context';
import { dataMenuLabels } from '@/data/menu.data';
import { VERSIONS_DATA } from '@/data/versions.data';
import DataLayer from '@/utils/DataLayer';
import getClientXFromDifferentEvents from '@/utils/getClientXFromDifferentEvents';
import scssStyles from '@/utils/scssStyles';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, { Navigation, Swiper as SwiperProps } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CarInfo from '../car-info';
import styles from './version.module.scss';
import PulseTextDesign from '../pulse-text-design';
import { Conditional } from '@/components';
import FastbackBg from '@components/SvgComponents/FastbackBg';
import ArrowButton from '@components/ArrowButton';

SwiperCore.use([Navigation]);

const pageSubsection = 'versoes';

const VersionsComponent: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [navController, setNavController] = useState<SwiperProps>();
  const { state, dispatch, VERSION_CONTEXT } = useContext(Context);
  const reference = dataMenuLabels().find((c) => c.slug === 'versoes');
  const [initialIndex, setInitialIndex] = useState<any>(0);
  const [touchLocation, setTouchLocation] = useState<PointerEvent['clientX']>();
  const [firstWraperPosition, setFirstWraperPosition] = useState('0px');
  const [lastWraperPosition, setLastWraperPosition] = useState('65px');
  const [wrapperTouchLocation, setWrapperTouchLocation] = useState<PointerEvent['clientX']>();

  useEffect(() => {
    setTimeout(() => {
      const index = VERSIONS_DATA.findIndex(
        (c) =>
          c.slug ===
          VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index].slug
      );
      if (index) {
        setInitialIndex(index);
        swiperInstance?.slideTo(index);
      } else {
        setInitialIndex(1);
      }
    }, 100);

    if (state.layout.galleryExpanded) {
      swiperInstance?.keyboard.disable();
    } else {
      swiperInstance?.keyboard.enable();
    }
  }, [
    initialIndex,
    dispatch,
    state.layout.currentVersion,
    state.layout.galleryExpanded,
    swiperInstance,
    swiperInstance?.keyboard,
  ]);

  const handleButtonClick = (index: number) => {
    swiperInstance?.slideTo(index);
    VERSION_CONTEXT.set_selected_version_index(index);
  };

  useEffect(() => {
    const activeColor = VERSIONS_DATA[
      VERSION_CONTEXT.get_selected_version_index
    ].colors.filter((value) => value.colorActive);
    VERSION_CONTEXT.set_selected_color_name(activeColor[0]?.colorNameFormatted);
    VERSION_CONTEXT.set_version_src(
      VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index]?.path
    );
  }, [VERSION_CONTEXT.get_selected_version_index]);

  return (
    <section className={styles.versions}>
      <div className={styles.container}>
        <div className={styles.title}>
          <Conditional notOn={'mobile'}>
            <h2>
              <strong>CONHEÇA </strong> TODAS AS VERSÕES DO FIAT FASTBACK
            </h2>
          </Conditional>
          <Conditional notOn={'desktop'}>
            <h2>
              TODAS AS VERSÕES DE <strong>FIAT FASTBACK</strong>
            </h2>
          </Conditional>
        </div>

        <Conditional notOn="mobile">
          {VERSION_CONTEXT.get_version.mvsCode !== '376AH70' ? (
            <div className={styles.bgFastbackDesktop}>
              <FastbackBg />
            </div>
          ) : (
            <div className={styles.bgAbarthDesktop}>
              <Image
                  src="/images/brand/logo-abarth-scorpion.svg"
                  alt="Logo"
                  width={500}
                  height={500}
                  layout="responsive"
              />
            </div>
          )}
        </Conditional>

          <Conditional notOn={'desktop'}>
              {VERSION_CONTEXT.get_version.mvsCode !== '376AH70' ? (
                  <div className={styles.bgFastbackMobile}>
                      <FastbackBg/>
                  </div>
              ) : (
                  <div className={styles.bgAbarthMobile}>
                    <Image
                        src="/images/brand/logo-abarth-scorpion-mobile.svg"
                        alt="Logo"
                        width={500}
                        height={500}
                        layout="responsive"
                    />
                  </div>
              )}
          </Conditional>

        <nav className={styles.navigation}>
          <Conditional notOn={'mobile'}>
            <div className={styles.detailWraper}>
              <div className={styles.detailsWrap}></div>
            </div>
          </Conditional>

          <Swiper
              onSwiper={(s) => setNavController(s)}
              controller={navController ? { control: navController } : undefined}
            allowTouchMove={true}
            slidesOffsetBefore={state.layout.isDesktop ? 0 : 0}
            slidesPerView={state.layout.isDesktop ? 5 : 2.8}
            spaceBetween={2}
            centeredSlides={!state.layout.isDesktop}
            className={styles.navswiper}

              onTouchStart={(_, event) => {
                const clientX = getClientXFromDifferentEvents(event);
                setWrapperTouchLocation(clientX);
                setFirstWraperPosition('-80px');

              }}
              onTouchEnd={(_, event) => {
                const clientX = getClientXFromDifferentEvents(event);
                if(wrapperTouchLocation && wrapperTouchLocation > clientX) {
                  setLastWraperPosition('0px')
                }
              }}
          >
            {VERSIONS_DATA.map((car, index) => {
              return (
                <SwiperSlide
                  className={scssStyles([
                    styles.navslide,
                    VERSION_CONTEXT.get_selected_version_index === index
                      ? styles.navslide_active
                      : '',
                  ])}
                  key={`car-navigattion-item-${index}`}
                >
                  {car.mvsCode === '376AH70' && (
                    <img
                      className={styles.abarth}
                      src={'/images/ui/abarth.svg'}
                      alt="Abarth"
                    />
                  )}
                  <PulseTextDesign
                    active={
                      VERSION_CONTEXT.get_selected_version_index === index
                    }
                    inverted={
                      index === VERSION_CONTEXT.get_selected_version_index
                    }
                    text={car.model.fullname}
                    handleClick={() => {
                      VERSION_CONTEXT.set_selected_version_index(index);
                      DataLayer.clickEvent({
                        element: car.model.fullname,
                        elementCategory: 'botao',
                        pageSection: 'conteudo',
                        pageSubsection,
                      });
                      handleButtonClick(index);
                    }}
                  />
                </SwiperSlide>
              );
            })}
            {/*<Conditional notOn={'desktop'}>*/}
            {/*  <div*/}
            {/*    className={styles.detailWraper}*/}
            {/*    style={{ justifyContent: 'flex-end', left: lastWraperPosition }}*/}
            {/*  >*/}
            {/*    <div className={styles.detailsWrap}></div>*/}
            {/*  </div>*/}
            {/*</Conditional>*/}
          </Swiper>
          <Conditional notOn={'mobile'}>
            <div
              className={styles.detailWraper}
              style={{ justifyContent: 'flex-end' }}
            >
              <div className={styles.detailsWrap}></div>
            </div>
          </Conditional>
        </nav>
      </div>

      <div className={styles.containerSwipper}>
        <Swiper
          loop={false}
          onSwiper={setSwiperInstance}
          onSlideChange={(swiper) => {
            navController?.slideTo(swiper.activeIndex);
            const selectedVersion = VERSIONS_DATA[swiper.activeIndex];

            console.log({ selectedVersion });
            VERSION_CONTEXT.set_version(selectedVersion);

            dispatch({
              type: 'SET_CURRENTLY_VISIBLE_NAV',
              payload: {
                view: reference,
                suffix: selectedVersion.slug,
              },
            });
            VERSION_CONTEXT.set_selected_version_index(swiper.activeIndex);
          }}
          pagination={false}
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides={true}
          initialSlide={initialIndex}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
          onTouchEnd={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            DataLayer.swipeEvent({
              element: (touchLocation ?? 0) > clientX ? 'proximo' : 'anterior',
              elementCategory: 'slide',
              pageSection: 'conteudo',
              pageSubsection: 'ver',
            });
          }}
        >
          {state.layout.requestFinished &&
            VERSIONS_DATA.map((car, i) => {
              return (
                <SwiperSlide key={`${car.id}-${i}`}>
                  <div className={styles.carousel}>
                    <div className={styles.boxImage}>
                      <Image
                        className={styles.images}
                        height={480}
                        width={775}
                        layout="intrinsic"
                        src={
                          VERSION_CONTEXT.get_selected_version_index === i
                            ? VERSION_CONTEXT.get_version_src
                            : car.path
                        }
                        alt={` ${car.model.altTag}`}
                        title={`${car.model.titleTag}`}
                        aria-label={`${car.model.altTag}`}
                        priority={
                          VERSION_CONTEXT.get_selected_version_index === i
                        }
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

          {/*<Chevron*/}
          {/*    className={styles.previous}*/}
          {/*    chevronAngule={0}*/}
          {/*    chevronColor={COLORS.BASIC.WHITE}*/}
          {/*    variant="secundary"*/}
          {/*    handleClick={() => {*/}

          {/*        swiperInstance?.slidePrev();*/}
          {/*    }}*/}
          {/*/>*/}
        </Swiper>
        <ArrowButton
          className={styles.previous}
          title="Arrow"
          previous
          handleClick={() => {
            DataLayer.clickEvent({
              element: 'anterior',
              elementCategory: 'seta',
              pageSection: 'conteudo',
              pageSubsection: 'fiat-fastback',
            });
            swiperInstance?.slidePrev();
          }}
        />

        <ArrowButton
          className={styles.next}
          title="Arrow"
          handleClick={() => {
            DataLayer.clickEvent({
              element: 'proximo',
              elementCategory: 'seta',
              pageSection: 'conteudo',
              pageSubsection: 'fiat-fastback',
            });
            swiperInstance?.slideNext();
          }}
        />
      </div>

      <CarInfo
        car={VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index]}
      />
    </section>
  );
};

export default VersionsComponent;
