import { ArrowButton, VimeoEmbed } from "@/components";
import { Context } from "@/context";
import productDetails, {
  allSlides,
  ProductTab,
  ProductTabSlide,
} from "@/data/product-details.data";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useRef, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./swiper-product-details-desktop.module.scss";
import Image from "next/image";
import DataLayer from "@/utils/DataLayer";
import { Chevron } from "@/components/chevron";
import { NewCta } from "@/components/new-cta";

SwiperCore.use([A11y, Keyboard, Thumbs]);

interface SlideProps {
  slide: ProductTabSlide;
  tab: ProductTab;
  productDetailsVersion?: 1 | 2;
}

interface SwiperProps {
  productDetailsVersion?: 1 | 2;
  setReference: (tab: any) => void;
}

const pageSubsection = "tudo-sobre-fastback-abarth";

const Slide: FC<SlideProps> = observer(
  ({ slide, tab, productDetailsVersion }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
      <div className={styles.slideContainer}>
        {(() => {
          if (slide.vimeoId?.desktop) {
            return (
              <div className={styles.videoWrapper}>
                <div className={styles.videoContainer}>
                  <VimeoEmbed
                    className={styles.videoHolder}
                    id={slide.vimeoId.desktop}
                    title={slide.title}
                  />
                  {/* <video
                    ref={videoRef}
                    autoPlay={true}
                    muted
                    loop={true}
                    playsInline
                    className={styles.video}
                  >
                    <source src={slide.videoId} /> */}
                  {/* </video> */}
                </div>
              </div>
            );
          } else {
            return (
              <div className={styles.imageHolder}>
                <Image
                  alt={slide.altImage}
                  title={slide.titleImage}
                  src={slide.image}
                  layout="fill"
                />
              </div>
            );
          }
        })()}
        <div className={styles.slideDetails}>
          <h4>{slide.titleToFront}</h4>
          <div className={styles.slideDescription}>
            {slide.descriptionDesktop}
            {!!slide.linkBtn && (
              <>
                <a
                  target="_blank"
                  href={slide.linkBtn}
                  key={`version-ctaasasd`}
                >
                  <NewCta
                    width={"40%"}
                    label={"item.label"}
                    handleClick={function (): void {
                      throw new Error("addd datalayer");
                    }}
                  />
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const SwipersDesktop: FC<SwiperProps> = observer(
  ({ productDetailsVersion, setReference }) => {
    const { state, dispatch } = useContext(Context);
    const [, rerender] = useState(false);
    const [touchLocation, setTouchLocation] =
      useState<PointerEvent["clientX"]>() || 0;
    const nextSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex + 1];
    const previousSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex - 1];

    const handleSwiperChange = async (tab: ProductTab) => {
      await dispatch({
        type: "SET_CURRENTLY_VISIBLE_NAV",
        payload: {
          tab: tab,
        },
      });

      await dispatch({
        type: "SET_DETAIL_TAB",
        payload: {
          selectedDetailTab: tab,
        },
      });
    };

    return (
      <div className={styles.container}>
        <Swiper
          observer
          observeParents
          className="containerPd"
          parallax
          onSwiper={e =>
            dispatch({
              type: "SET_PRODUCT_DETAILS_CONTROLLER",
              payload: { productDetailsSwiperController: e },
            })
          }
          controller={
            state.layout.productDetailsSwiperController
              ? { control: state.layout.productDetailsSwiperController }
              : undefined
          }
          spaceBetween={100}
          onSlideChange={swiper => {
            const index = swiper.activeIndex;
            const tab = productDetails.find(
              p => p.id === allSlides[index].tabId
            );
            if (tab) {
              handleSwiperChange(tab);
              setReference(tab);
            }
            rerender(r => !r);
          }}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
          onTouchEnd={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            const touchLocation2 = touchLocation ?? 0;

            touchLocation2 > clientX &&
              DataLayer.swipeEvent({
                element: "proximo",
                elementCategory: "imagem",
                pageSection: pageSubsection,
                pageSubsection:
                  nextSlide?.parentSlug ??
                  productDetails.find(
                    c => c.id === state.layout.selectedDetailTab
                  )?.slug ??
                  "",
              });

            touchLocation2 < clientX &&
              DataLayer.swipeEvent({
                element: "anterior",
                elementCategory: "imagem",
                pageSection: pageSubsection,
                pageSubsection:
                  previousSlide?.parentSlug ??
                  productDetails.find(
                    c => c.id === state.layout.selectedDetailTab
                  )?.slug ??
                  "",
              });
          }}
        >
          {productDetails.map(tab =>
            tab.children.map(slide => (
              <SwiperSlide key={`detail-${tab.id}-${slide.id}`}>
                <Slide
                  tab={tab}
                  slide={{ ...slide, tabId: tab.id }}
                  productDetailsVersion={productDetailsVersion}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
        <div className={styles.controls}>
          {state.layout.pageX >= 1200 && (
            <div className={styles.bullets}>
              {allSlides
                .filter(t => t.tabId === state.layout.currentTab?.id)
                .map((c, index) => {
                  return (
                    <button
                      key={`bullet-sw-${c.id}`}
                      title={c.title}
                      className={scssStyles([
                        styles.bullet,
                        state.layout.productDetailsSwiperController
                          ?.activeIndex === c.index
                          ? styles.active
                          : "",
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `item-${index + 1}`,
                          elementCategory: "icone",
                          pageSection: pageSubsection,
                          pageSubsection:
                            productDetails.find(
                              c => c.id === state.layout.selectedDetailTab
                            )?.slug ?? "",
                        });
                        if (
                          c.index &&
                          state.layout.productDetailsSwiperController
                        ) {
                          state.layout.productDetailsSwiperController?.slideTo(
                            c.index
                          );
                        }
                      }}
                    >
                      <span />
                    </button>
                  );
                })}
            </div>
          )}
          <div className={styles.arrows}>
            <ArrowButton
              title="Arrow"
              previous
              disabled={
                (state.layout.productDetailsSwiperController?.activeIndex ??
                  0) -
                  1 <
                0
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "anterior",
                  elementCategory: "icone",
                  pageSection: pageSubsection,
                  pageSubsection:
                    productDetails.find(
                      c => c.id === state.layout.selectedDetailTab
                    )?.slug ?? "",
                });
                state.layout.productDetailsSwiperController?.slidePrev();
              }}
            />

            <ArrowButton
              title="Arrow"
              disabled={
                (state.layout.productDetailsSwiperController?.activeIndex ??
                  allSlides.length) >=
                allSlides.length - 1
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "proximo",
                  elementCategory: "seta",
                  pageSection: pageSubsection,
                  pageSubsection:
                    productDetails.find(
                      c => c.id === state.layout.selectedDetailTab
                    )?.slug ?? "",
                });
                state.layout.productDetailsSwiperController?.slideNext();
              }}
            />

            {nextSlide?.firstChildrenTab && (
              <div className={styles.nextTab}>
                <strong>Próximo</strong>
                <p>{nextSlide?.title}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default SwipersDesktop;
