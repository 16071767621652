import { Conditional, SectionElement } from "@/components";
import { Context } from "@/context";
import { PRODUCT_DETAILS_REF } from "@/data/menu.data";
import productDetails, {
  allSlides,
  getProductDetails,
} from "@/data/product-details.data";
import SwipersDesktop from "@/sections/product-details-section/product-details-swiper-component/swiper-product-details-desktop.component";
import SwipersMobile from "@/sections/product-details-section/product-details-swiper-component/swiper-product-details-mobile.component";
import Tabs from "@/sections/product-details-section/product-details-tabs-component";
import { FC, useContext } from "react";
import styles from "./product-details-2.module.scss";

const ProductDetails2: FC = () => {
  const { state, dispatch } = useContext(Context);

  const setReference = (tab?: any) => {
    let reference;
    let suffix;
    const index = !state.layout.isDesktop
      ? state.layout.productDetailsMobileSwiperController[
          state.layout.selectedDetailTab ?? 0
        ]?.activeIndex
      : state.layout.productDetailsSwiperController?.activeIndex;

    if (tab) {
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${tab?.id}`
      );
      if (!state.layout.isDesktop) {
        suffix = tab.children[index ?? 0].slug;
      } else {
        suffix = allSlides[index ?? 0].slug;
      }
    } else {
      suffix = allSlides[index ?? 0].slug;
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${state.layout.currentTab?.id}`
      );
    }

    if (reference) {
      if (state.layout.selectedDetailTab) {
        dispatch({
          type: "SET_CURRENTLY_VISIBLE_NAV",
          payload: { view: reference, suffix: suffix },
        });
      }
    }
  };

  return (
    <SectionElement
      id="ProductDetails"
      navReference={PRODUCT_DETAILS_REF}
      className={styles.container}
      slugPrefixes={productDetails.map(p => p.slug)}
      onSlugSuffix={slug => {
        if (slug) {
          const slide = allSlides.find(s => s.slug === slug);
          const index = allSlides.findIndex(s => s.slug === slug);

          if (slide && state.layout.productDetailsSwiperController?.params) {
            state.layout.productDetailsSwiperController.slideTo(
              index >= 0 ? index : 0
            );
          }
          if (
            slide &&
            state.layout.productDetailsMobileSwiperController?.params
          ) {
          }
        }
      }}
      onVisibilityChange={visible => {
        if (visible) {
          setTimeout(() => {
            setReference();
          }, 100);
        }
      }}
    >
      <h2 className={styles.title}>
        TUDO SOBRE O NOVO {!state.layout.isDesktop && <br />}
        <strong>FIAT FASTBACK</strong>
      </h2>
      <Conditional
        desktop={
          <>
            <div className={styles.containerArea}>
              <Tabs flexDirection={"column"} />
              <div className={styles.contentArea}>
                <SwipersDesktop
                  productDetailsVersion={2}
                  setReference={setReference}
                />
              </div>
            </div>
          </>
        }
        mobile={<SwipersMobile setReference={setReference} />}
      />

      {state.layout.isDesktop && (
        <svg
          className={styles.flags}
          id="flags"
          xmlns="http://www.w3.org/2000/svg"
          width="200.173"
          height="11.046"
          viewBox="0 0 200.173 11.046"
        >
          <path
            id="Caminho_4630"
            data-name="Caminho 4630"
            d="M261.584,0,258,11.046h1.659L263.244,0Z"
            transform="translate(-111.803 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4631"
            data-name="Caminho 4631"
            d="M304.584,0,301,11.046h1.659L306.244,0Z"
            transform="translate(-130.437 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4632"
            data-name="Caminho 4632"
            d="M347.584,0,344,11.046h1.659L349.243,0Z"
            transform="translate(-149.071 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4633"
            data-name="Caminho 4633"
            d="M132.584,0,129,11.046h1.659L134.243,0Z"
            transform="translate(-55.901 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4634"
            data-name="Caminho 4634"
            d="M3.584,0,0,11.046H1.659L5.243,0Z"
            transform="translate(0 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4635"
            data-name="Caminho 4635"
            d="M46.584,0,43,11.046H44.66L48.244,0Z"
            transform="translate(-18.634 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4636"
            data-name="Caminho 4636"
            d="M89.584,0,86,11.046h1.659L91.244,0Z"
            transform="translate(-37.268 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4637"
            data-name="Caminho 4637"
            d="M175.584,0,172,11.046h1.659L177.243,0Z"
            transform="translate(-74.535 0)"
            fill="#ff1430"
          />
          <path
            id="Caminho_4638"
            data-name="Caminho 4638"
            d="M218.584,0,215,11.046h1.659L220.244,0Z"
            transform="translate(-93.169 0)"
            fill="#ff1430"
          />
        </svg>
      )}
    </SectionElement>
  );
};

export default ProductDetails2;
