import { ArrowButton, Conditional, SectionElement, YoutubeEmbed } from "@/components";
import { Chevron } from "@/components/chevron";
import { Context } from "@/context";
import { INSIDE_DATA } from "@/data/blacksection.data";
import { dataMenuLabels } from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import scssStyles from "@/utils/scssStyles";
import Image from "next/image";
import { FC, useContext, useRef, useState } from "react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./connect-me.module.scss";
import ConnectMeSvg from "@/components/SvgComponents/ConnectMe";
import Flag from "@/components/SvgComponents/Flag";
import ConnectmeLogo from "@/components/SvgComponents/ConnectmeLogo";

const reference = dataMenuLabels().find((c) => c.slug === "connectme");
const pageSubSection = "connect-me";
export const BlackHawkSection: FC = () => {
  const { state } = useContext(Context);
  const [indexSlide, setIndexSlide] = useState<number>(0);
  const [controller, setController] = useState<SwiperProps>();
  const [touchLocation, setTouchLocation] = useState<PointerEvent["clientX"]>();

   const videoRefs = useRef<(HTMLIFrameElement | null)[]>([]);

   const pauseCurrentVideo = () => {
     if (videoRefs.current[indexSlide]) {
       videoRefs.current[indexSlide]?.contentWindow?.postMessage(
         JSON.stringify({ event: "command", func: "pauseVideo", args: "" }),
         "*"
       );
     }
   };
  return (
    <SectionElement
      navReference={reference}
      className={scssStyles([styles.container, styles.bg])}
    >
      <div className={styles.titleContent}>
        <ConnectmeLogo />
      </div>
      {/* card */}
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        slidesOffsetAfter={0}
        slidesOffsetBefore={0}
        onSwiper={s => setController(s)}
        controller={controller ? { control: controller } : undefined}
        effect="flip"
        onSlideChange={swiper => {
          setIndexSlide(swiper.activeIndex);
        }}
        onTouchStart={(_, event) => {
          const clientX = getClientXFromDifferentEvents(event);
          setTouchLocation(clientX);
        }}
        onTouchEnd={(_, event) => {
          const clientX = getClientXFromDifferentEvents(event);
          DataLayer.swipeEvent({
            element: (touchLocation ?? 0) > clientX ? "proximo" : "anterior",
            elementCategory: "slide",
            pageSection: "conteudo",
            pageSubsection: pageSubSection,
          });
        }}
        className={styles.slidewraper}
      >
        {INSIDE_DATA.map((card, index) => {
          return (
            <SwiperSlide className={styles.slide} key={index}>
              <div className={styles.filledText}>
                <h2 className={styles.title}>{card.title}</h2>
                <p>{card.description}</p>
              </div>

              {!state.layout.isDesktop && (
                <div className={styles.controlsMobile}>
                  {/* Bullets */}

                  <ArrowButton
                    title="Arrow"
                    previous
                    handleClick={() => {
                      DataLayer.clickEvent({
                        element: "anterior",
                        elementCategory: "seta",
                        pageSection: "conteudo",
                        pageSubsection: pageSubSection,
                      }),
                        controller?.slidePrev();
                      pauseCurrentVideo();
                    }}
                    disabled={indexSlide <= 0}
                  />

                  <div className={styles.bullets}>
                    {INSIDE_DATA.map((e, index) => {
                      return (
                        <span
                          key={index}
                          className={scssStyles([
                            index === indexSlide ? styles.bulletActive : "",
                          ])}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `item-${index + 1}`,
                              elementCategory: "icone",
                              pageSection: "conteudo",
                              pageSubsection: pageSubSection,
                            });
                            controller?.slideTo(index);
                            pauseCurrentVideo();
                          }}
                        />
                      );
                    })}
                  </div>
                  {/* Arrows */}
                  <ArrowButton
                    disabled={indexSlide + 1 == INSIDE_DATA.length}
                    title="Arrow"
                    handleClick={() => {
                      DataLayer.clickEvent({
                        element: "anterior",
                        elementCategory: "seta",
                        pageSection: "conteudo",
                        pageSubsection: pageSubSection,
                      }),
                        controller?.slideNext();
                      pauseCurrentVideo();
                    }}
                  />
                </div>
              )}

              {(() => {
                if (card.srcVideo) {
                  return (
                    <div className={styles.filledVideo}>
                      <YoutubeEmbed
                        className={styles.videoHolder}
                        title={card.titleTag}
                        youtubeVideoId={card.srcVideo}
                        isActive={index === indexSlide}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className={styles.filledImage}>
                      <Image
                        src={card.src?.desktop || ""}
                        layout="fill"
                        objectFit="contain"
                        alt={card.altTag}
                        title={card.titleTag}
                      />
                    </div>
                  );
                }
              })()}
            </SwiperSlide>
          );
        })}
        {state.layout.isDesktop && (
          <div className={styles.controls}>
            {/* Bullets */}
            <div className={styles.bullets}>
              {INSIDE_DATA.map((e, index) => {
                return (
                  <span
                    key={index}
                    className={scssStyles([
                      index === indexSlide ? styles.bulletActive : "",
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${index + 1}`,
                        elementCategory: "icone",
                        pageSection: "conteudo",
                        pageSubsection: pageSubSection,
                      });
                      controller?.slideTo(index);
                      pauseCurrentVideo();
                    }}
                  />
                );
              })}
            </div>
            {/* Arrows */}
            <div className={styles.arrows}>
              <ArrowButton
                title="Arrow"
                previous
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: "anterior",
                    elementCategory: "seta",
                    pageSection: "conteudo",
                    pageSubsection: pageSubSection,
                  }),
                    controller?.slidePrev();
                  pauseCurrentVideo();
                }}
                disabled={indexSlide <= 0}
              />
              <ArrowButton
                disabled={indexSlide + 1 == INSIDE_DATA.length}
                title="Arrow"
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: "anterior",
                    elementCategory: "seta",
                    pageSection: "conteudo",
                    pageSubsection: pageSubSection,
                  }),
                    controller?.slideNext();
                  pauseCurrentVideo();
                }}
              />
            </div>
          </div>
        )}
      </Swiper>
      <div className={styles.testeLogo}>
        <Flag />
      </div>
    </SectionElement>
  );
};
