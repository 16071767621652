import { FC } from "react";
import styles from "./footer.module.scss";
import { dataSocial } from "@/data/footer.data";
import {
  FINANCIAMENTO_TITLE,
  CONCESSIONARIAS_TITLE,
  links,
  NEGOCIART_TITLE, OFERTAS_TITLE,
} from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import { CTAButton } from "@/components";
import FlagFiatFooter from "@/components/SvgComponents/FlagFiatFooter";

const layerEvent = (element: any) => {
  DataLayer.clickEvent({
    elementCategory: "cta",
    element,
    pageSection: "conteudo",
    pageSubsection: `vamos-negociar`,
  });
};
const Footer: FC = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.negocie}>
        <div className={styles.title}>
          <p>
            VAMOS <span>NEGOCIAR</span>?
          </p>
        </div>
        <div className={styles.ctas}>
          <CTAButton
            width={"100%"}
            href={links.financiamento}
            text={FINANCIAMENTO_TITLE}
            title={FINANCIAMENTO_TITLE}
            handleClick={() => layerEvent(FINANCIAMENTO_TITLE)}
          />{" "}
          <CTAButton
            href={links.concessionarias}
            width={"100%"}
            title={CONCESSIONARIAS_TITLE}
            text={CONCESSIONARIAS_TITLE}
            handleClick={() => layerEvent(CONCESSIONARIAS_TITLE)}
          />{" "}
          <CTAButton
            href={links.ofertas}
            width={"100%"}
            text={OFERTAS_TITLE}
            title={OFERTAS_TITLE}
            handleClick={() => layerEvent(OFERTAS_TITLE)}
          />
        </div>
      </div>
      <div className={styles.social}>
        <div>
          {dataSocial.map(social => {
            const tracking = () => {
              DataLayer.clickEvent({
                element: social.title,
                elementCategory: "icone",
                pageSection: "footer",
                pageSubsection: "social",
              });
            };
            return (
              <a
                key={social.id}
                target="_blank"
                href={social.url}
                title={social.title}
                onClick={() => {
                  tracking();
                }}
                onAuxClick={() => {
                  tracking();
                }}
              >
                <img
                  src={social.icon}
                  title={social.title}
                  alt={social.title}
                />
              </a>
            );
          })}
        </div>
        <p className={styles.description}>
          A disponibilidade de itens de série, opcionais e acessórios pode
          variar de acordo com a versão escolhida.
          <br />
          Verifique o Monte seu Carro. Imagens meramente ilustrativas.
        </p>
      </div>
      <FlagFiatFooter/>
    </footer>
  );
};

export default Footer;
