const MOBILE_PREFIX = `${process.env.BASE_PREFIX}images/Stories/`;
const DESKTOP_PREFIX = `${process.env.BASE_PREFIX}images/hero/`;
const MOBILE_VIDEO_PREFIX = `${process.env.BASE_PREFIX}videos/Stories/`;
const DESKTOP_VIDEO_PREFIX = `${process.env.BASE_PREFIX}videos/Hero/`;

export const HERO_ASSETS = {
  desktop: {
    slide1: `${DESKTOP_PREFIX}fiat-fastback.webp`,
    slide2: `${DESKTOP_PREFIX}design-suv-coupe.webp`,
    slide3: `${DESKTOP_PREFIX}performance.webp`,
    slide4: `${DESKTOP_PREFIX}espaco.webp`,
    slide5: `${DESKTOP_PREFIX}powered-by-abarth.webp`,
    video: [
      `${DESKTOP_VIDEO_PREFIX}PERFORMANCE-04_16x9_1.mp4`,
    ],
  },


  mobile: {
    slide1: `${MOBILE_PREFIX}fiat-fastback.webp`,
    slide2: `${MOBILE_PREFIX}design-suv-coupe.webp`,
    slide3: `${MOBILE_PREFIX}performance.webp`,
    slide4: `${MOBILE_PREFIX}espaco.webp`,
    slide5: `${MOBILE_PREFIX}powered-by-abarth.webp`,
    video: [
      `${MOBILE_VIDEO_PREFIX}PERFORMANCE-04_9x16_1.mp4`,
    ],
  },
};
