import { FC } from "react";

const FlagFiatFooter: FC<{ }> = ({
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="126.332"
      height="91.818"
      viewBox="0 0 126.332 1"
    >
      <g id="Group_4287" data-name="Group 4287" transform="translate(0)">
        <path
          id="Path_4626"
          data-name="Path 4626"
          d="M35.645,0,5.857,91.818H19.639L49.437,0Z"
          transform="translate(21.729 0)"
          fill="#ff1430"
        />
        <path
          id="Path_4627"
          data-name="Path 4627"
          d="M29.793,0,0,91.818H13.806L43.594,0Z"
          transform="translate(0 0)"
          fill="#ff1430"
        />
        <path
          id="Path_4628"
          data-name="Path 4628"
          d="M41.5,0,11.711,91.818H25.5L55.3,0Z"
          transform="translate(43.451 0)"
          fill="#ff1430"
        />
        <path
          id="Path_4629"
          data-name="Path 4629"
          d="M47.36,0,17.567,91.818H31.354L61.152,0Z"
          transform="translate(65.18 0)"
          fill="#ff1430"
        />
      </g>
    </svg>
  );
};

export default FlagFiatFooter;
