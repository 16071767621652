import {FC, useContext, useEffect} from 'react';
import {Context} from '@/context';
import DataLayer, {formatString} from '@/utils/DataLayer';
import {PREFIX} from '@/utils/imagePrefixes';
import styles from './car-info.module.scss';
import {
    IVersionsData,
    VERSIONS_DATA,
    VERSION_YEAR_BASE,
    getVersionImageColor,
    getVersionsColorURL,
    getVersionsModelURL,
    getVersionsPriceURL,
    handleReturnDefaultColorByVersion,
} from '@/data/versions.data';
import ColorChanger from '../color-change';
import scssStyles from '@/utils/scssStyles';
import {NewCta} from '../new-cta';
import {Tooltip} from '@mui/material';
import PCDToolTip from './car-info-tooltip';
import {ui} from "@/assets";
import Image from 'next/image';

interface CarInfoProps {
    car?: IVersionsData;
}

const AbarthText: FC = () => {
    return (
        <>
            <div className={styles.logoAbarth}/>
            <Image alt="Abarth-text" src={ui.textAbarth} width={364} height={110}/>
        </>
    );
};


const CarInfo: React.FC<CarInfoProps> = ({car}) => {
    const {state, dispatch, VERSION_CONTEXT, COMPARATIVE_CONTEXT} =
        useContext(Context);

    const availableColors = async (mvsCode: string) => {
        const dataColor = await fetch(
            getVersionsColorURL(mvsCode, VERSION_YEAR_BASE)
        );
        const colorResponse = await dataColor.json();
        const colorResponseLength = colorResponse.length;
        const dataPrice = await fetch(
            getVersionsPriceURL(mvsCode, VERSION_YEAR_BASE)
        );
        const priceResponse = await dataPrice.json();

        VERSIONS_DATA.map((item) => {
            if (item.mvsCode === mvsCode) {
                colorResponse.map(
                    (value: { rgb: string; code: string; name: string }, i: number) => {
                        if (item.colors.length < colorResponseLength) {
                            item.colors.push({
                                colorName: value.name,
                                colorCode: value.code,
                                colorRGB: value.rgb,
                                colorNameFormatted: formatString(value.name),
                                pathSrc: `${PREFIX}Versions/${formatString(value.name)}.webp`,
                                colorActive:
                                    formatString(value.code) ===
                                    handleReturnDefaultColorByVersion(mvsCode),
                            });
                        }
                    }
                );
                item.price = priceResponse.price.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                });
            }
        });

        const defaultColor = VERSIONS_DATA.find(
            (item) => item.mvsCode === mvsCode
        )?.colors.find((item) => item.colorActive);

        dispatch({
            type: 'SET_REQUEST_FINISHED',
            payload: {requestFinished: true},
        });

        dispatch({
            type: 'SET_COLOR_ACTIVE',
            payload: {colorActive: defaultColor?.colorNameFormatted},
        });

        if (!defaultColor) return;

        VERSION_CONTEXT.set_selected_color_name(defaultColor?.colorNameFormatted);
        VERSION_CONTEXT.set_selected_color(defaultColor?.colorCode);

        VERSION_CONTEXT.set_version_src(
            getVersionImageColor(
                VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index].mvsCode,
                defaultColor?.colorCode
            )
        );

        return dataColor;
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(getVersionsModelURL(VERSION_YEAR_BASE));
            const json = await data.json();

            json.map((item: { code: string }) => {
                availableColors(item.code);
            });
        };

        fetchData().catch(console.error);
    }, []);


    return (
        <div className={styles.main}>
            <div className={scssStyles([styles.containerCarInfo, styles.info])}>
                {!state.layout.isDesktop && state.layout.requestFinished && (
                    <ColorChanger/>
                )}
                <div className={styles.optional}>
                    <div className={styles.title}>
                        <div className={styles.versionText}>
                            {car?.isTurbo && (
                                <div className={styles.versionNew}>
                                    {/*<img alt={car.model.cardName} src={ui.info005}/>*/}
                                </div>
                            )}

                            {car && car.type !== 'abarth' ? (
                                <>
                                    <div className={styles.versionTextWraper}>
                                        <h2 className={styles.versionTextName}>
                                            {car?.model.cardName.toUpperCase()}
                                        </h2>
                                        <div className={styles.logoAbarthWrap}>
                                            <img
                                                className={`${car?.isAbarth ? '' : styles.displayFlag}`}
                                                alt={car?.model.cardName}
                                                src={ui.logoAbarth}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={scssStyles([
                                            styles.versionNameSvg,
                                            car?.isTurbo ? styles.isTurbo : '',
                                        ])}
                                    >
                                        {car?.isAbarth ? (
                                            <img alt={car?.model.cardName} src={ui.info001}/>
                                        ) : car?.isTurbo && !car?.isAbarth ? (
                                            <img alt={car?.model.cardName} src={ui.info004}/>
                                        ) : (
                                            <img alt={car?.model.cardName} src={ui.info003}/>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <AbarthText />
                            )}
                        </div>
                        <div className={styles.redLine} style={{height: '4px'}}/>

                        <p className={styles.description}>{car?.description}</p>
                    </div>
                    <ul className={styles.thumbs}>
                        {car?.optionals.map((optional, index) => (
                            <li key={index}>
                                <div className={styles.imageHolder}></div>
                                <div className={styles.textHolder}>
                                    <p>{optional.text}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {state.layout.isDesktop && state.layout.requestFinished && (
                    <ColorChanger/>
                )}
                <div className={styles.price}>
                    {state.layout.requestFinished && (
                        <>
                            <div className={styles.payment}>
                                {car?.pcd && (
                                    <Tooltip
                                        placement="left-end"
                                        describeChild
                                        title={<PCDToolTip/>}
                                    >
                                        <img src="/images/ui/pcd.svg" alt="pcd"/>
                                    </Tooltip>
                                )}

                                <div>
                                    <p>A partir de</p>
                                    <p>
                                        <strong>R$ {car?.price ? car.price : '- - '}</strong>
                                    </p>
                                </div>

                                <div className={styles.simular}>
                                    <a
                                        onClick={() =>
                                            DataLayer.clickEvent({
                                                element: 'simular-parcelas',
                                                elementCategory: 'cta',
                                                pageSection: 'conteudo',
                                                pageSubsection: 'versoes',
                                            })
                                        }
                                        href="https://fastback.fiat.com.br/monte.html?openFundingPlans=true#simule-o-financiamento"
                                        target="_blank"
                                    >
                                        Simular parcelas
                                    </a>
                                </div>
                            </div>
                        </>
                    )}

                    <div className={styles.ctas}>
                        <div className={styles.ctaLinks}>
                            {car?.versionCtaButton.map((item, index) => {
                                const layerEvent = () => {
                                    DataLayer.clickEvent({
                                        elementCategory: 'cta',
                                        element: item.label,
                                        pageSection: 'versao',
                                        pageSubsection: `fastback-abarth-${
                                            VERSIONS_DATA[VERSION_CONTEXT.get_selected_version_index]
                                                .slug
                                        }`,
                                    });
                                };
                                return (
                                    <a
                                        target="_blank"
                                        href={item.url}
                                        key={`version-cta${item.id}-${index}`}
                                    >
                                        <NewCta
                                            width={state.layout.isDesktop ? '172px' : '100%'}
                                            label={item.label}
                                            handleClick={() => layerEvent()}
                                            secundary={index !== 0}
                                        />
                                    </a>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </div>
            <div className={styles.cardFooter}/>
        </div>
    );
};

export default CarInfo;
