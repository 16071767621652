import { PRODUCT_DETAILS_PREFIX } from "@/data/menu.data";
import productDetails, { ProductTab } from "@/data/product-details.data";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import styles from "./product-details-tabs.module.scss";
import { Context } from "@/context";
import DataLayer from "@/utils/DataLayer";

interface Tabs {
  flexDirection?: "row" | "column";
}

const Tab: FC<{ tab: ProductTab }> = observer(({ tab }) => {
  const { state, dispatch } = useContext(Context);
  const pageSubsection = "tudo-sobre";
  const handleSelectedDetailTab = (tab: ProductTab): void => {
    dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        selectedDetailTab: tab,
      },
    });
  };

  useEffect(() => {
    if (state.layout.selectedDetailTab === "") {
      dispatch({
        type: "SET_DETAIL_TAB",
        payload: { selectedDetailTab: productDetails[0] },
      });

      dispatch({
        type: "SWIPE_TO_TAB",
        payload: { tab: productDetails[0] },
      });
    }
  }, [dispatch, state.layout.selectedDetailTab]);

  return (
    <a
      className={scssStyles([
        styles.tab,
        state.layout.selectedDetailTab === tab.id ? styles.active : "",
      ])}
      href={`/${PRODUCT_DETAILS_PREFIX}/${tab.slug}`}
      onClick={(e) => {
        DataLayer.clickEvent({
          element: tab.title,
          elementCategory: "botao",
          pageSection: "conteudo",
          pageSubsection,
        });
        dispatch({
          type: "SET_DETAIL_TAB",
          payload: { selectedDetailTab: tab },
        });

        dispatch({
          type: "SWIPE_TO_TAB",
          payload: { tab },
        });
        handleSelectedDetailTab(tab);

        e.preventDefault();
      }}
    >
      <div className={styles.content}>
        <h3 className={styles.title}>{tab.title}</h3>

        {state.layout.selectedDetailTab === tab.id && (
          <svg
            id="Fiat_Flag"
            data-name="Fiat Flag"
            xmlns="http://www.w3.org/2000/svg"
            width="18.254"
            height="8.575"
            viewBox="0 0 18.254 8.575"
          >
            <path
              id="Caminho_149"
              data-name="Caminho 149"
              d="M500.97,300.235l-4.3,8.575h1.993l4.3-8.575Z"
              transform="translate(-492.679 -300.235)"
              fill="#e72121"
            />
            <path
              id="Caminho_150"
              data-name="Caminho 150"
              d="M320.874,300.235l-4.3,8.575h1.993l4.3-8.575Z"
              transform="translate(-316.569 -300.235)"
              fill="#e72121"
            />
            <path
              id="Caminho_151"
              data-name="Caminho 151"
              d="M681.065,300.235l-4.3,8.575h1.993l4.3-8.575Z"
              transform="translate(-668.789 -300.235)"
              fill="#e72121"
            />
            <path
              id="Caminho_152"
              data-name="Caminho 152"
              d="M861.161,300.235l-4.3,8.575h1.993l4.3-8.575Z"
              transform="translate(-844.899 -300.235)"
              fill="#e72121"
            />
          </svg>
        )}
      </div>
      <div
        className={styles.border}
        style={{
          backgroundColor:
            state.layout.selectedDetailTab === tab.title ? "#B0A477" : "",
        }}
      />
    </a>
  );
});

const Tabs: FC<Tabs> = ({ flexDirection }) => {
  return (
    <div className={styles.container}>
      {productDetails.map((tab) => (
        <Tab key={`tab--${tab.id}`} tab={tab} />
      ))}
    </div>
  );
};

export default Tabs;
